header{
  .header{
    &-content{
      position: relative;
      z-index: 3;
    }
    &-top{
      &-container{
        @include flex;
        justify-content: flex-end;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      &-info{
        @include contentMedium;
        padding-left: 1rem;
        position: relative;
        ul{
          @include flexWrap;
          justify-content: center;
          li{
            font-size: 1.5rem;
            font-weight: 300;
            width: 100%;
            text-align: center;
            &.mobile{
              display: none;
            }
            @media only screen and (max-width: #{$mediumBreakpoint}) /* 1024px */ {
              &.desktop{
                display: none;
              }
              &.mobile{
                display: inline-block;
              }
            }
            a{
              color: $colorWhite;
              padding: .4rem 2rem .4rem 0;
              display: block;
              @include flex;
              align-items: center;
              &:before{
                display: inline-block;
                height: auto;
                line-height: 0;
                margin-right: .5rem;
              }
            }

            &:last-child{
              a{
                padding-right: 0;
                &:before{
                  width: 1.8rem;
                  height: 1.8rem;
                  background: $colorWhite;
                  color: $colorPrimary;
                  content: "?";
                  font-size: 1.6rem;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 1.8rem;
                  align-items: center;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
      &-logo{


        .nav-toggle{
          display: none;
        }
        .logo{
          position: relative;
          a{

          }
          img{

          }
        }
      }
      &-search, &-account{
        margin-right: 3rem;
      }
      &-search{
        width: auto;
        margin-left: 1.5rem;
        .block{
          &-title{
            padding: 0;
            margin: 0;
            border:0;
            cursor: pointer;
            &:before{
              content: "\f002";
              @include fontIcon;
              font-size:  1.8rem;
            }
            strong{
              display: none;
            }
          }
          &-content{
            display: none;
          }
        }
        .active{
          .block{
            &-title{
              position: relative;
              &:before{
                content: "\f00d";
                font-size: 2.5rem;
                font-weight: 300;
              }
            }
            &-content{
              width: 100%;
              background: $colorDarkBrown;
              position: absolute;
              left: 0;
              top: 100%;
              display: block;
              border-top: 2px solid $colorSecondary;
              z-index: 99;
              form{
                @include flex;
                padding: 1rem;

                .search{
                  width: 100%;
                  input{
                    height: 100%;
                    border:0;
                  }
                }
                .action{
                  &.search{
                    padding:1.5rem;
                    height: 100%;
                    border:0;
                    margin-right: 0;
                    display: block;
                    background: $colorWhite;
                    &:before{
                      content: "\f002";
                      @include fontIcon;
                      color: $colorSecondary;

                    }
                    span{
                      display: none;
                    }
                  }
                }
                .control{
                  height: 100%;
                }
                .label{
                  display: none;
                }
              }
            }
          }
        }
      }
      &-account{
        a{
          i{
            font-size:  2rem;
            color: $colorGrey;
          }
        }
      }
      &-cart{
        .minicart-wrapper{
          a{
            padding: 0;
            display: block;
            margin: 0;
            position: relative;
            &:before{
              content: "\f07a";
              @include fontIcon;
              color: $colorGrey;
              font-size:  2rem;

            }
            .text{
              display: none;
            }
            .counter {
              &.empty{
                .counter {
                  &-number {
                    display: none;
                  }
                }
              }
              &-number {
                background: $colorGreen;
                display: inline-block;
                color: $colorWhite;
                width: 2rem;
                height: 2rem;
                font-size: 1.2rem;
                line-height: 2rem;
                text-align: center;
                position: absolute;
                top: -10px;
                right: -15px;
                border-radius: 50px;
                font-weight: 800;
                letter-spacing: -1px;
              }
              &-label{
                display: none;
              }
            }
          }
        }

      }
    }
    &-main{
      width: 100%;
      position: relative;
      &.sticky{
        position: fixed;
        top: -5rem;
        background: $colorWhite;
        -webkit-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
        -moz-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
        box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
        .header {
          &-main {
            &-logo{
              padding-bottom: 0;
              a{
                &:after{
                  display: block;
                  position: absolute;
                  bottom: -3rem;
                  right: 0;
                }
                img{
                  opacity: 0;
                }
              }
            }
            &-container{

            }
            &-title {
            }
          }
        }
      }
      &-title{
        width: 100%;
        a{
          display: inline-block;
        }
      }
      &-container{
        width: 100%;
        @include contentInside;
        text-align: left;
        @include flex;
        justify-content: space-between;
        align-items: flex-end;
        top: initial;
        background: $colorDarkBrown;
        position:relative;
        z-index: 2;


        h1{
          font-size: 3.3rem;
          color: $colorPrimary;
          padding: 0;
          text-align: left;
          line-height: normal;
        }
      }
      &-navigation{
        width: auto;
        &.mobile{
          display: none;
        }
        ul{
          @include flex;
          white-space: nowrap;
          margin-top: 0;
          &.level{
            &0, &1 {
              display: none;
            }
          }
          li{
            a{
              padding: 1.5rem 2rem;
              display: block;
              color: $colorWhite;
              font-weight: normal;
              text-transform: uppercase;
              font-size: 1.4rem;
              background: transparent;
            }
            &.level0{
              > ul{
                position: absolute;
                left: 0;
                top: 100%;
                z-index: 2;
                width: 100%;
                background: $colorSecondary;
                border-top: 1px solid $colorWhite;
              }
            }
            &.active{
              background: $colorSecondary;

              a{
                color: $colorPrimary;
              }
              &.parent{
                > a{
                  &:after{
                    color: $colorPrimary;
                  }
                }
              }
            }
            > ul{
              justify-content: center;
              li{
                a{
                  padding: .5rem 1rem;
                  width: 100%;
                  color: $colorGrey;
                  background: $colorSecondary;
                  font-size: 1.4rem;
                  transition: all ease .2s ;
                  text-transform: none;
                  &:hover{
                    color: $colorPrimary;
                  }
                }
              }
            }
            &:hover{
              > a{
                background: $colorSecondary;
                color: $colorPrimary;
                &:after{
                  color: $colorSecondary;
                }
              }
              > ul{
                padding: 2rem 4rem;
                @include flexWrap;
                > li{
                  > a{

                  }
                }
              }
            }
            &.parent{
              @include flex;
              align-items: center;
              > a{
                padding-right: 3rem;
                position: relative;
                &:after{
                  content: "\f107";
                  @include fontIcon;
                  font-size: 1rem;
                  color: $colorWhite;
                  position: absolute;
                  right: 1rem;
                }
              }
              &:hover{
                > a{
                  &:after{
                    color: $colorPrimary;
                  }
                }
              }
            }
            &.nav-1{
              &:hover{
                >ul{
                  display: inline-block;
                }
              }
              > ul{
                justify-content: flex-start;
                column-count: 3;
                columns: 3;
                -webkit-columns: 3;
                -moz-columns: 3;
                li{

                  width: 100%;
                }
              }
            }
          }
        }
        .navigation{
          &-footer{
            display: none;
          }
        }
      }
    }
  }
  .inner {
    &-main {
      &-container {
        @include flexWrap;
        align-items: center;
        &.left{
          width: 100%;
        }
        &.right{
          width: 100%;
          max-width: 14rem;
          justify-content: flex-end;
        }
      }
    }
  }
}
.navigation-header{
  display: none;
}
