body, html{
  overflow: auto;
  background: $colorLightGrey;
}
.home {
  .page-main {
    padding-top: 0;
  }
}
header, section, footer, aside, nav, main, article, figure {
  display: block;
}
/*Spide*/
.elementor-widget .widget-content .splidef .splide__slide.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
}
.elementor-widget .widget-content .splide {
  visibility: visible !important;
}
ul.splide__pagination{
  bottom: 0;
  justify-content: center;
  li {
    button.is-active {
      background-color: $colorSecondary;
      transform: none;
      opacity: 1;


    }
    button {
      border-radius: 0;
      border: 0rem solid white;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      transition: none;
      opacity: 1;
      color: rgba($colorSecondary, 0.3);
      width: 5rem;
      height: 3px;
      &:before {
        color: transparent;
      }

    }
  }
}
.splide__arrow.splide__arrow{
  width: 4rem;
  height: auto;
  background-color: transparent;
  top: 40%;
  display: flex;
  align-items: center;
  font-size: 3rem;
  position: absolute;
  &:before{
    @include fontIcon;
    height: auto;
    width: auto;
    display: inline-block;
    color: #AFAFAF;
  }
  &--prev {
    &:before{
      content: url($imageDir + "arrow-left.png");
    }
  }
  &--next{
    right: 1em;
    left: inherit;
    &:before{
      content: url($imageDir + "arrow-right.png");
    }
  }
}

button.splide__arrow.splide__arrow--next, button.splide__arrow.splide__arrow--prev {
  svg {
    display: none;
  }
}

button.slick-prev.slick-arrow {
  width: 4rem;
  height: 4rem;
  right: 6rem;
  left: inherit;
  bottom: -7rem;
  top: inherit;
  transform: rotate(180deg);
  background-color: transparent;
  &:before {
    color: transparent;
  }
}
button.slick-next.slick-arrow{
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  right: 0rem;
  left: inherit;
  bottom: -9rem;
  top: inherit;
  &:before {
    color: transparent;
  }
}
p{
  line-height: 1.42857143;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
.to-top{
    position: fixed;
    bottom: 13rem;
    right: -6rem;
    width: 3.9rem;
    height: 3.9rem;
    z-index: 3;
    background: $colorButton;
    transition: all ease .2s;
    @include flex;
    align-items: center;
    justify-content: center;
    color: $colorWhite;
    font-size: 2rem;
    cursor: pointer;

    &.active{
        right: 0;
    }
}
.inner-container{

}
/*1 Col Layout*/
.cms-impressum.page-layout-1column .page-main, body.cms-datenschutz main, .cms-agbs main {
  h1 {
    line-height: 1;
  }
}

.cms-page-view, .cms-index-defaultnoroute{
  .column{
    .inner-container{
      font-size: 1.6rem;
      line-height: 2rem;
      p{
        padding-bottom: 1.8rem;
        line-height: 2.5rem;
        font-size: 1.5rem;
      }
      h1, h2, h3, h4{
        text-align: left;
        color: $colorText;
      }
      h2{
        font-size: 2.1rem;
        line-height: normal;
      }
      
      ul{
        padding-left: 1.5rem;
        li{
          list-style: disc;
        }
      }
    }
  }
}
.related-search-terms{
  width: 48%;
  display: inline-block;
  vertical-align: top;
  margin-right: 1%;
  padding-bottom: 2rem;
  &:nth-child(2 of .related-search-terms){
    margin-right: 0;
  }
}


/*2 Col Layout*/
body{
  &:not(.cms-home) {
    .columns {
      @include contentInside;
      color: $colorText;
      font-weight: normal;
      font-size: 1.5rem;
      padding-bottom: 2.5rem;
      padding-top: 2.5rem;
      p {
      }
    }
    .page-title {
      padding: 2.5rem 0;
      margin-bottom: 0;
      text-align: left;

    }
  }
}
.page-layout {

  &-2columns-left {
    .page {
      &-main {
        padding-top: 0rem;

        .products-grid {
          padding-bottom: 2rem;
          border-bottom: 0.1rem solid $colorLightGrey;
          margin-left: -4rem;
          padding-left: 4rem;
          padding-right: 5rem;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
      &-wrapper .columns {
        margin-top: 0px;
        line-height: 2.6rem;
      }
    }
    .columns {
      @include contentInside;
      padding-bottom: 6rem;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}
.cms-page-view, .customer-account-login{
  .columns{
    .column{

    }
  }

  .page-title{
    font-size: 2rem;
    line-height: normal;
  }
}
.block {
  &.related{
    width: 100%;
  }
  &-title {
    @include flexWrap;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem 0 4rem;
    font-size: 2rem;
    border-bottom: 0;
    > * {
      width: auto;
    }

    h2, h3 {

      padding: 0;
      color: $colorText;
      font-size: 4rem;
      font-weight: bold;
      line-height: normal;
    }

    h3 {
      color: $colorPrimary;
      padding-left: 1rem;

    }

    .description {
      @include flex;
      justify-content: center;
      width: 100%;
      padding: 3.8rem 0;

      p {
        color: $colorText;
        max-width: $mediumContent;
        text-align: center;
        @include fontDefault;
      }
    }
  }
}
.page-title {
  text-align: left;
  &-wrapper {
    @include contentInside;
    text-align: left;
    width: 100%;

    h1 {
      margin-bottom: 0;
      color: $colorText;
      font-size: 4rem;
      line-height: normal;
    }
  }
}

.becomeamember {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  top: 0;
}
.product{
  &-item{
    &-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      padding: 1rem;
      @include flex;
      justify-content: center;
      flex-direction: column;
      .new + .award{
        margin-top: 1rem;
      }
      .new, .award{
        position: relative;
        @include flex;
        span{
          position: absolute;
          @include flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-weight: bold;
          width: 100%;
          height: 100%;
          display: none;
        }
        &:before{
          content: "";
          width: 7rem;
          height: 7rem;
          background-size: cover;
        }
      }
      .new{
        span{
          font-size: 2.4rem;
        }
        &:before{
          background-image: url($imageDir + "new.png");
        }
      }
      .award{
        &:before{
          background-image: url($imageDir + "award.png");
        }
      }
    }
  }
}
.block{

}
.cms-page-view, .catalog-product-view, .checkout-cart-index{
  .header{
    &-main{
      -webkit-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      -moz-box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      box-shadow: 0px 4px 5px 0px rgba($colorSecondary,0.05);
      &-container{

      }
    }
  }
  .page{

    &-header{

    }
  }
}
.products.wrapper + .toolbar{
  justify-content: center;
  .toolbar-amount{
    display: none;
  }
  .pages{
    display: inline-block;
    width: 100%;
  }
  .limiter,  .sorter{
    display: none;
  }
}
.toolbar {
  margin-bottom: 3.2rem;
  @include flexWrap;
  justify-content: space-between;
  .modes{
    display: none;
  }
  .limiter, .sorter{
    @include flex;
    align-items: center;
    font-size: 1.7rem;
    font-weight: bold;
    .control{

    }
    label{
      margin-right: 1rem;
      color: $colorText;
    }
  }
  select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    @include fontDefault;
    width: auto;
    font-weight: normal;
    cursor: pointer;
    padding: .3rem 1rem;
    padding-right:  2.5rem;
    color: $colorPrimary;
    border: 1px solid rgba($colorLightGrey, 0.2);
  }
  option{
    @include fontDefault;
    font-size: 1.6rem;
    line-height: normal;
    font-weight: normal;
  }
  .sorter{
    margin-left: 3.5rem;
    &-label {

    }
    &-action {
      display: none;
    }
  }
  &.customer-addresses-toolbar {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: right;
    margin: 0;
  }
  /*Toolbar Sorter*/
  &-sorter {
    text-align: right;

    align-items: center;
    z-index: 1;
    .sorter {
    }
  }
  .pages {
    display: none;
  }
  .limiter{
    display: none;
  }
  &-amount{

  }
}
.page {
  &s {
    > strong {
      display: none;
    }
    ul {
      @include flex;
      align-items: center;
      justify-content: center;

      li {
        @include flex;
        align-items: center;
        justify-content: center;

        &.item {
          margin-right: 1rem;

          a, strong {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            text-align: center;
            font-weight: normal;
            width: 4.5rem;
            height: 4.5rem;
            @include flex;
            align-items: center;
            font-size: 1.8rem;
            background-color: #484C520D;

            span {
              display: inline-block;
              width: 100%;
              text-align: center;
            }
          }

          .label {
            display: none;
          }
        }


        &.pages {
          &-item {
            &-previous, &-next {
              a {
                height: 4.5rem;
                width: 4.5rem;
                background-repeat: no-repeat;
                text-align: center;

                &:before {
                  @include fontIcon;
                  text-align: center;
                  display: inline-block;
                  width: 100%;
                  font-size: 1.5rem;
                }
              }
            }

            &-previous {
              a {
                &:before {
                  content: "\f104";
                }

                span {
                  display: none;
                }
              }
            }

            &-next {
              a {
                &:before {
                  content: "\f105";
                }

                span {
                  display: none;
                }
              }
            }
          }
        }

        &.current {
          strong {
            background-color: $colorPrimary;
            color: $colorWhite;
          }
        }
      }
    }
  }
  &-main {
    background: #fff;
  }
}
.green{
  color: $colorPrimary !important;
}

/*Page*/
.page-wrapper {
  @include contentMedium;
  /*Breadcrumbs*/
  .breadcrumbs{
    font-size: 1.4rem;
    @include contentInside;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin-bottom: 0;
    background: $colorWhite;
    ul{
      @include flexWrap;
      li{
        font-weight: 500;
        margin-right: 0.35rem;
        color: $colorText;
        font-size: 1.2rem;
        &:after{
          content: "/";
        }
        a{
          color: $colorPrimary;
        }
        strong{

        }
        &:last-child{
          &:after{
            content: "";
          }
        }
        > *{
          font-weight: 500;
        }
      }
    }
  }
  .block.block-addresses-list + div {
    display: flex;
  }
  /*Columns*/
  .columns {
    .main {

      /*Toolbar*/

      .products-grid + .toolbar-products .pages,
      .posts-grid + .toolbar-posts .pages{
        display: block;
        margin-bottom: -3rem;
        + .toolbar-sorter {
          display: none;
        }
      }
      .products-grid + .toolbar-products .toolbar-sorter {
        display: none;
      }
      .products-grid + .toolbar-products {
        margin-top: 3rem;
      }
      .category-image {
        @include flexCenter;
        margin-bottom: 6rem;
        img {
          width: 100%;
          @include contentMedium;
        }
      }
      .category-description {
        padding-top: 3rem;
        p{
          font-size: 1.4rem;
          line-height: 1.42857143;
        }
      }

      /*old & special Price*/
      .price-final_price {
        .percentage {
          color: $colorPrimary;
          @include fontSmaller;
          margin-right: 1.5rem;
        }
        .special-price {


          .price{
            font-weight: bold;
          }
          .price-label {

          }
        }
        .old-price {
          @include flexCenter;
          color: $colorText;
          opacity: .5;

          span {
            @include fontSmaller;
          }
          .price-label {

          }
        }
      }
    }
  }
  .block.filter {
  }
  .page-sharing-wrapper {
    position: absolute;
    top: -3rem;
    right: 0;
  }
}

.product-grid.three {
  .products.list {
    justify-content: center !important;
  }
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
  padding: 0 !important;
}
.error404 {
  .page-info-main {
    text-align: center;
  }
}
.widget.magento-widget.category-overview {
  a.action.tocategory.secondary {
    border-color: #000;
    color: #000;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    &:hover {
      box-shadow: 0 0 1px 1px #000;
    }
  }
}
.wishlist-index-index {
  .page-wrapper {
    .columns {
      .main {
        .products.products-grid {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 4.5rem;
          margin-bottom: 2rem;
          margin-left: 0;
          margin-right: 0;
          .products.list {
            margin-bottom: 0.5rem;
          }
        }
        .actions-toolbar > .primary {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
.widget-content {
  margin-top: -5rem;
}
.widget-title {
  position: relative;
  max-width: 134rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  .title-link {
    text-align: right;
    display: block;
    @include fontSmaller;
    font-weight: bold;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: -8rem;
  }
}
.column {
  width: 100%;
}

.customer-account-logoutsuccess, .archive {
  .main {
    text-align: center;
  }
}

.privacy-policy {
  .widget.elementor-widget.text-teaser.small.left {
    margin-bottom: 0;
  }
}
.elementor-widget:not(:last-child) {
  margin-bottom: 0px !important;
}
/*Pagination*/
