.checkout{
  &-index{
    &-index{
      header {
        .header-content {
          height: auto;
        }
      }
      .columns{
        padding-bottom: 6rem;
      }
      .page-main{
        padding-top: 0;
      }
    }
  }
  &-container{
    @include flexWrap;
    justify-content: space-between;
    padding-bottom: 6rem;
  }
  &-agreement {
    @include flexWrap;
    margin-bottom: 1rem;

    &:last-child{
      margin-bottom: 0;
    }
    input{
      width: auto;
    }
    .action{
      padding: 0 {
        left: 1.2rem;
      };
      margin: 0;
      text-align: left;
      font-size: 1.4rem;
      font-weight: normal;
      color: $colorText;
      background: transparent;
    }
    &s {
      margin-bottom: 1rem;
    }
  }
  &-shipping{
    &-method{
      margin-top: 4rem;
    }
  }
  &-billing{
    &-address{
      margin-bottom: 3rem;
      .payment-method-content &{
        .field {
          select, input {
            max-width: 100%;
            &.checkbox{
              max-width: 25rem;
              width: auto;
            }
          }
        }
      }

      select, input{
        width: 100%;
      }
      .billing-address-details{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.note{
  font-size: 1.4rem;
  line-height: normal;
}
.address{
  > .field{
    margin-bottom: 1.5rem;
    .control {
      input, select {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
.billing{
  &-address{
    &-details{
      background: $colorSecondary;
      padding: 1.7rem 2rem;
      margin-top: 1rem;
      font-size: 1.6rem;
      br{
        display: block;
      }
    }
    &-same-as-shipping-block{
      @include flex;
      input{
        width: auto;
      }
      label{
        width: 100%;
        padding-left: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}
.minicart{
  &-items{
    .product{
      @include flex;
      font-size: 1.4rem;
      line-height: normal;
      padding-top: 2rem;
      &.options{
        @include flexWrap;
        display: none;
        > span, > div{
          width: 100%;
        }
      }
      &-image-container{
        min-width: 7.5rem;
      }
      &-item{
        &-inner{
          padding-bottom: 2rem;
          .details-qty{
            padding-bottom: 1rem;
          }
        }
        &-name{
          padding-bottom: 1rem;
          display: block;
        }
        &-details{
          padding-left: 1rem;
        }
      }
    }
  }
}
#checkout-shipping-method-load{
  background: $colorSecondary;
  padding: 2rem;
  margin-bottom: 3rem;
  table tbody{
    border:0
  }
}
.step {
  &-title {
    font-weight: bold;
    line-height: 1;
    font-size: 2.4rem;
    padding-bottom: 1.6rem;
    display: block;
  }
  &-content{
    table.table-checkout-shipping-method{
      td{
        &.col{
          width: 100%;
          text-align: left;
          &-method{
            &:first-child{
              width:1.5rem;
              padding: 0;
            }
          }
          input{
            width: auto;
          }
        }
      }
    }
  }
}
.opc{
  &-estimated-wrapper{
    display: none;
  }
  &-progress-bar {
    padding-bottom: 5rem;
    padding-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    counter-reset: i;
    max-width: 100%;

    li {
      margin: 0 0 1rem;
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 50%;
      max-width: calc(50% - .7rem);
      opacity: .3;
      &:before {
        background: $colorGrey;
        top: 2.9rem;
        content: "";
        height: 0.1rem;
        left: 0;
        position: absolute;
        width: 100%;
      }

      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;
      }

      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }

      > span {
        display: block;
        padding-bottom: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        color: $colorGrey;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.2rem;

        &:after {
          left: 50%;
          position: absolute;
          margin-left: -1.3rem;
          top: 2rem;
          content: counter(i);
          counter-increment: i;
          background: white;

          border-radius: 50%;
          border: 0.5px solid $colorPrimary;
          height: 2.3rem;
          width: 2.3rem;
          font-size: 1.4rem;

          font-weight: normal;
          color: $colorPrimary;
          background-size: 70%;
          content: counter(i);
          counter-increment: i;
          @include flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
      }

      &._active {
        opacity: 1;
        &:before {
          background: $colorPrimary;
        }

        > span {
          color: $colorPrimary;

          &:after {
            border: 1px solid $colorPrimary;
          }
        }
      }

      &._complete {
        cursor: pointer;
        opacity: 1;
        &:before {
          background: $colorPrimary;
        }

        > span {
          color: $colorPrimary;

          &:after {
            content: "\f058";
            @include fontIcon;
            font-weight: 900;
            border-color: $colorPrimary;
            font-size: 24px;
            color: $colorPrimary;
          }
        }
      }
    }
  }
  &-wrapper{
    width: 100%;
    max-width: calc(100% - 39rem);
  }
  &-block {
    &-shipping{
      &-information{

        .action{

        }
      }
    }
    &-summary {
      border: 1px solid $colorBorder;
      padding: 2.1rem 3rem;
      table{
        @extend .totals;
        margin-top: 1.6rem;
        th, td {
          line-height: 1;
          padding: 0;
          font-size: 1.6rem;
          padding-bottom: 1.6rem;
          font-weight: normal;
          text-align: left;
        }
      }
    }
  }
  &-sidebar{
    width: 36rem;
    margin-top: 2.8rem;
    header{
      display: none;
    }
  }
}
.shipping{
  &-cost{
    &-details{
      a{
        font-weight: bold;
      }
    }
  }
  &-address{
    &-item{
      margin-bottom: 1.5rem;
      margin-right: 2%;
      width: 32%;
      padding: 2rem;
      border: 1px solid $colorLighterGrey;
      opacity: .5;
      @include transition;
      &:hover{
        opacity: 1;
      }
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &.selected-item{
        border-color: $colorPrimary;
        opacity: 1;
        background: $colorSecondary;
      }
      button.action{
        padding: 1rem;
        width: 100%;
        background: $colorPrimary;
        color: $colorWhite;
        margin-top: 2rem;
        margin-left: 0 !important;
      }
      &s{
        @include flexWrap;
      }
    }
  }
  &-information{
    @extend .cart-membership;
    &-title{
      font-weight: bold;
      padding-bottom: 1.3rem;
      border-bottom: 1px solid $colorBorder;
      margin-bottom: 1.4rem;
      @include flex;
      .action-edit{
        background: transparent;
        color: $colorPrimary;
        padding: 0;
        span{
          display: none;
        }
        &:before{
          @include fontIcon;
          content: $iconEdit;
        }
      }
      > span{
        &:first-child{
          width: 100%;
        }
      }

    }
  }
}
.ship{
  &-via{
    margin-top: 1rem;
  }
}
.items{
  &-in{
    &-cart{
      padding-top: 2rem;
      .title{
        cursor: pointer;
        @include flexCenter;
        justify-content: flex-start;
        font-size: 2rem;
        border-bottom: 0;
        strong{
          display: inline-block;
        }
        &:before{
          @include fontIcon;
          margin-right: 1rem;
          content: $iconArrow;
          font-weight: 700;
          font-size: 1.3rem;
          @include transition;
          transform: rotate(90deg);
          display: inline-block;
          vertical-align: middle;
        }
      }
      &.active{
        .title{
          border-bottom: 1px solid $colorBorder;
          &:before{
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.payment{
  &-method{
    background: $colorSecondary;
    padding: 1.7rem 2rem;
    border-bottom: 2px solid $colorWhite;
    .fieldset{
      padding-bottom: 0;
    }

    &-title{

      font-weight: bold;
      input{
        width: auto;
        margin-right: 1rem;
      }
    }
    &-content{
      display: none;
      padding-top: 2rem;
      form{

      }
    }
    &._active{
      .payment{
        &-method{
          &-content{
            display: block;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
          }
        }
      }
    }
  }
  &s{
    .legend{
      @extend .title;
      border:0;
    }
  }
  &-option {
    border-bottom: 1px solid $colorGrey;
    padding-bottom: 1.5rem;
    &-content{
      .messages{
        max-width: 100%;
        .message{
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
    &-title {
      margin: 1.5rem 0 0;
      .action{
        display: block;
        padding: 1rem 0;
        color: $colorPrimary;
        text-align: left;
      }
    }
  }
}




/*Loader*/
.load {
  &er {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(50% - 22px);
    top: calc(50% - 22px);
    p  {
      display: none;
    }
    > img {
      max-width: 4.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 9999;
    }
  }
  &ing-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0rem;
    top: 0;
    z-index: 9999;
    background: rgba($colorGrey, 0.5);
  }
  &.indicator {
    background-color: rgba($colorGrey, 0.5);
    z-index: 9999;

    &:before {
      border-radius: 0.5rem;
      box-sizing: border-box;
      content: "";
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }

    > span {
      display: none;
    }
  }
}
.order{
  &-comment{
    margin-bottom: 1rem;
    &-title{
      font-weight: bold;
    }
    .action{
      display: inline-block;
      padding: 0;
      margin-bottom: 1rem;
    }
    textarea{
      resize: none;
    }
    &._collapsible{
      margin-bottom: 1rem;
      border-bottom: 1px solid $colorBorder;
      @include transition;
      .order-comment-title{

        @include flexBetween;
        align-items: center;


        &:after{
          @include fontIcon;
          content: $iconArrow;
          font-size: 1.4rem;
          transform: rotate(180deg);
          @include transition;

        }
      }
      &._active{
        border-bottom: 1px solid transparent;
        .order-comment-title {

          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
