/*Product Detail*/
.preview-giftcard{
  background-size: 100% !important;
}
.catalog-product-view {

  .modal-popup {
    &._show .modal-inner-wrap {

    }
  }
  button.splide__arrow{
    &.splide{
      &__arrow{
        &--prev{
          left: -8rem;
        }
        &--next{
          right: -8rem;
        }
      }
    }
  }
  .main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .page-wrapper {
      padding-top: 0;
    }
    .webcrossing-widget-product-slider {
      .block {
        &-title {
          h2 {
            text-align: left;
          }
        }
        &-content{
          .items {
            .item {
              &.content {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  .product{
    text-align: left;
    &-tab{
      &-expertise{
        margin-top: 1rem;
        @include flex;
        .label{
          display: block;
        }
        .value, a{
          @include flex;
          align-items: center;
          font-weight: bold;
        }
      }
    }
    &-info{
      &-labels{
        .special_price, .new{
          margin-bottom: 2.3rem;
        }
      }
      &-media {

        position: relative;
        width: 48%;

        margin-bottom: 5.1rem;
        margin-right: 4%;
        .fotorama {
          &__img {
            background: $colorWhite;
            top: 0;
            transform: translate3d(-50%, 0, 0);
          }
          &__thumb-border{
            border-color: $colorPrimary;
          }
        }
        .fotorama__stage__frame.fotorama__active.fotorama_vertical_ratio.fotorama__loaded.fotorama__loaded--img {
          img {

          }
        }
        .fotorama__wrap--toggle-arrows{
          background: transparent;
        }
        .fotorama__arr {
          background: transparent;
          width: 4rem;
          &__arr{
            background: no-repeat center center;
            top: 40%;
          }
          &--prev,  &--next{
            margin-top: 8rem;
            transition: all ease .2s;
            position: absolute;
          }
          &--prev {
            .fotorama__arr__arr{
              background-image: url($imageDir + "arrow-left.png");
            }

          }

          &--next {
            .fotorama__arr__arr{
              background-image: url($imageDir + "arrow-right.png");
            }
          }
        }
        .action.skip {
          display: none;
        }
        .gallery-placeholder div {
        }
        .fotorama__nav-wrap.fotorama__nav-wrap--horizontal {

        }
        .fotorama__nav--dots .fotorama__nav__frame {
          height: inherit;
          width: inherit;
        }
        .fotorama__dot {
          width: 9px;
          height: 9px;
          top: inherit;
          left: inherit;
          border-radius: 50%;
          margin-left: 1.3rem;
          margin-right: 1.3rem;
        }
        .fotorama__active .fotorama__dot {
          background-color: black;
          border-color: black;
        }
        .fotorama__fullscreen-icon:focus:after, .fotorama__zoom-out:focus:after, .fotorama__zoom-in:focus:after, .fotorama__arr:focus:after, .fotorama__stage__shaft:focus:after, .fotorama__nav__frame--thumb:focus .fotorama__thumb:after, .fotorama__nav__frame--dot:focus .fotorama__dot:after {
          box-shadow: none !important;
        }
        .fotorama__stage:before, .fotorama__nav:before, .fotorama__stage:after, .fotorama__nav:after {
          display: none !important;
        }
        a.action.skip.gallery-next-area, .action-skip-wrapper {
          display: none;
        }
        .giftcard {
          &-template {
            &-setting{
              display: none;
            }
          }
        }
      }/* End -media */
      &-main {
        width: 48%;
        line-height: 2.7rem;
        padding-bottom: 6rem;
        display: flex;
        flex-direction: column;

        .page-title{

          padding-bottom: 0;
          margin-bottom: 0;
          text-align: left;
          padding-top: 0;
          width: 100%;
          font-size: 4rem;
          line-height: normal;

          @media only screen and (max-width: 768px) {
            padding-bottom: 1.5rem;
          }
          span.price-label {
            display: none;
          }
          .swatch-attribute-label {
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.15rem;
            display: block;
            line-height: 1;
            padding-bottom: 1.3rem;
          }
          &-wrapper{
            padding: 0;
            max-width: 100%;
            .short_description{
              line-height: 2.2rem;
              font-weight: 400;
            }
          }
        }
        .round{
          width: 1.6rem;
          height:1.6rem;
          display: inline-block;
          border-radius: 50px;
          margin: .2rem 1rem .2rem 0;
          vertical-align: middle;
        }
        .green{
          background-color: #99c21c;
        }
        .turkis {
          background-color: #a1d5d4;
        }
        .blue {
          background-color: #4e91a0;
        }
        .orange {
          background-color: #e67d42;
        }
        .red {
          background-color: #a83743;
        }


      }/* End -media */
      &-category {
        ul {
          @include flex;
          li {
            a.action.tocategory.secondary {
              margin-right: 2rem;
              border-color: #C8C8C8;
              color: #000;
              &:hover {
                box-shadow: 0 0 1px 1px #000;
              }
            }
            &:first-child {
              a {

              }
            }
          }
        }
      }/* End -category */
      &-new {
        position: absolute;
        right: 19.5rem;
        top: -2rem;
        @include icon;
        width: 11.5rem;
        height: 11.5rem;
        span {
          display: none;
        }
      }/* End -new */
      &-special-price {
        position: absolute;
        right: 19.5rem;
        top: -2rem;
        @include icon;
        width: 11.5rem;
        height: 11.5rem;
        span {
          display: none;
        }
      }/* End -special-price */
      &-icon {
        a {
          @extend .emptyButton;
          color: transparent;
          border-radius: 0;
          span {
            display: none;
          }
        }
      }/* End -icon */
      &-brand {
        color: $colorSecondary;
        font-size: 2.5rem;
        text-align: left;
        font-weight: 300;
        strong {
          font-weight: normal;
        }
      }/* End -brand */
      &-spice_type {
        padding-bottom: 2.5rem;
        strong {
          @include fontLarger;
        }
      }/* End -spice_type */
      &-short{
        &-description{
          font-size: 1.8rem;
          color: $colorSecondary;
          font-weight: normal;
        }
      }
      &-description{
        padding: 0 10rem 5rem;
        color: $colorSecondary;
        border:0;
        h3{
          text-align: left;
          color: $colorSecondary;
          margin-bottom: 4.5rem;
          font-size: 3.6rem;

        }

      }
      &-detail{
        padding: 0 0 1rem;
        .details{
          ul{
            @include flex;
            justify-content: space-between;
            li{
              width: 31%;
              background: $colorGrey;
              padding: 4rem 5rem;
              color: $colorSecondary;
              font-size: 1.8rem;
              h3{
                text-align: left;
                margin-bottom: 2.5rem;
                font-weight: bold;
                font-size: 2.3rem;

              }
            }
          }

        }
      }
      &-deliverytime{
        padding-bottom: 4.5rem;
        > *{
          vertical-align: middle;
        }
      }
    }/* End -info */
    &-add {
      &tocart-popup{
        display: none;
      }
      &-form {


        .swatch-opt {
          display: flex;
          flex-direction: column;

          .swatch-attribute.size {
            order: 1;
            margin-bottom: 2.3rem;
          }

          .swatch-attribute.color {
            order: 0;
            .color{
              @include transition;
              opacity: 0.6;
              &:hover{
                opacity: 1;
              }
            }

            .selected{
              opacity: 1;
              border: 2px solid $colorSecondary;
            }
          }
        }

        .swatch-attribute {
          &.color {
            padding: 2rem 0;
            @media only screen and (max-width: 768px) {
              padding-bottom: 4.5rem;
            }
          }

          &-label {
            margin-bottom: 1.3rem;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            line-height: normal;
            display:  none;
          }

          &-options {
            @include flex;
            align-items: flex-start;

            .swatch-option {
              &.image {
                border-radius: 0;

                width: auto;
                height: auto;
                padding: 0;
                img {
                  border: 1px solid $colorBorder;
                  position: relative;

                }

                .label {
                  width: 100%;
                  display: block;
                  font-style: italic;
                  font-size: 1rem;
                  margin-top: 0.5rem;
                  line-height: normal;
                  border: 1px solid $colorWhite;
                }

                &.selected {
                  border: 0;
                  img {
                    border: 2px solid $colorBlack;
                  }
                }
              }

              &.text {
                width: auto;
                border: 0.5px solid rgba($colorSecondary, 0.2);
                height: 3.6rem;
                border-radius: 0;
                @include flexCenter;
                margin-right: 1.2rem;
                font-size: 1.4rem;
                min-width: 3.5rem !important;
                padding: 0 1rem;
                line-height: normal;
                &.selected {
                  border: 0.5px solid $colorPrimary;
                  background: $colorPrimary;
                  color: $colorWhite;
                }
              }
            }
          }
        }

        .box-tocart {

          padding-bottom: 3rem;

          .fieldset {
            width: 100%;
            @include flex;
            .field.spice_amount {
              width: calc(100% - 32rem);
            }
            .field.qty {
              width: auto;
              @include flexCenter;
              label {
                letter-spacing: 0.09rem;
                margin-right: 0.7rem;
                display: none;
              }
              .control {
                @include flex;
                align-items: center;
                input {
                  &#qty {
                    border: 1px solid $colorBorder;
                  }
                }

                .remove, .add {
                  cursor: pointer;
                  text-decoration: none;
                  text-align: center;
                  width: 2.2rem;
                  height: 2.2rem;
                  border: 0.1rem solid $colorBlack;
                  border-radius: 50%;
                  font-size: inherit;
                  line-height: 1;
                  font-weight: normal;
                  color: transparent;
                }
                .remove {
                  @include background;
                  background-size: 1rem;
                }
                .add {
                  @include background;
                  background-size: 1rem;
                }
                .input-text.qty {
                  @include fontDefault;
                  width: 9.2rem;
                  text-align: center;
                  text-decoration: none;
                  font-size: 1.8rem;
                  border: none;
                  line-height: normal;
                  padding-top: 1.2rem;
                  padding-bottom: 1.2rem;
                  color: $colorPrimary;
                }
              }
            }
            .actions {
              margin-top: 0;
              min-width: 19rem;
              min-height: 0;
              margin-left: 1.5rem;
              @include flexEnd;
              button {
                margin-right: 0;
                text-transform: normal;
                border: 0;
                background: $colorButton;
                @include font;
                line-height: normal;
              }
            }
          }
        }

        .giftcard {
          &-template {
            &-container {
            }

            &-preview {
              max-width: 8rem;
              display: none;

              > div {
                max-width: 100%;
              }
            }

            &-setting {
              padding-bottom: 5rem;

              .block-title {
                margin-bottom: 1.4rem;
              }

              &-select {
                > div {
                  @include flex;
                }
              }
            }

            &-preview {

            }
          }

          &-design {
            &-button {
              &-container {
                margin-right: 1.2rem;
                max-width: 8rem;

                i {
                  line-height: normal;
                }

                img {
                  border: 2px solid $colorWhite;
                }

                span {
                  width: 100%;
                  display: block;
                  font-style: italic;
                  font-size: 1rem;
                  margin-top: 0.5rem;
                  line-height: normal;
                  border: 1px solid #fff;
                }

                button {
                  border: 0;
                  background: transparent;
                  @include fontDefault;
                }

                &.active {
                  img {
                    border: 2px solid $colorPrimary;
                  }

                }
              }
            }
          }
        }
      }

      /* End -add-form */
    }
    &-info {

      &-price {
        margin-top: 2rem;
        @media only screen and (max-width: 768px) {
          padding-bottom: 3rem;
        }
        .price-label{
          display: none;
        }
        .price-final_price {
          font-size: 2.2rem;
          line-height: normal;
          color: $colorText;
          span{
            .price-label{
              display: none !important;
            }
          }
        }
        .price-box.price-final_price {
          @include fontLarger;
          text-align: left;
          justify-content: flex-start;
          @include flex;
          .percentage {
            margin-right: 2rem;
          }
        }
        .sale-price{
          order: 1;
        }
        .price-details {
        }
      }

    }
    &.attribute.description {
      font-size: 1.6rem;
      padding-bottom: 3rem;
      line-height: 2rem;
      max-width: 54rem;
    }
    &-options{
      &-stock, &-shipping, &-sku, &-country, &-size-chart, &-stock-status{
        font-size: 1.2rem;
      }
      &-size-chart{
        padding-bottom: 2rem;
        text-decoration: underline;
        > div{
          display: none;
        }
        span{
          cursor: pointer;
        }
      }
      &-stock-status{
        span{
          i{
            color: #1A9B37;
          }
        }
      }
    }
    &.detailed {
      width: 100%;
      padding-top: 3rem;

      .items {
        position: relative;
        .item {
          &.title {
            float: left;
            width: auto;
            padding-right: 1rem;
            border-bottom: 0;
            line-height: normal;
            a {
              display: block;
              position: relative;
              z-index: 2;
              min-height: auto;
              box-sizing: border-box;
              cursor: pointer;
              padding: 1rem 1.5rem;
              min-width: 14rem;
              text-align: left;
              text-transform: uppercase;
              font-weight: normal;
              background: $colorSecondary;
              border: 1px solid $colorSecondary;
              font-size: 1.4rem;
            }
            &.active {
              a {
                background: $colorWhite;
                padding-bottom: 1.3rem;
                border: 1px solid $colorPrimary;
                border-bottom: 0;
              }
              &+.content .inner-content {
                opacity: 1;
              }

            }
            &:first-child a {
            }
            &:last-child a{
            }
          }
          &.content {
            float: right;
            margin-left: -100%;
            width: 100%;
            margin-top: 4.2rem;
            border: 1px solid $colorPrimary;
            padding: 2rem;
            &.active {
              display: block;
            }
            .inner-content {
              opacity: 0;
              margin: 0 auto;
              font-size: 1.5rem;
              line-height: 2.5rem;
              .label{
                display: inline-block;
                font-weight: bold;
              }
              &.facts, &.awards{
                .label{
                  min-width: 200px;
                  width: auto;
                }
                .value{
                  display: inline-block;
                }
                ul{
                  li{
                    @include flex;
                  }
                }
              }
              &.details{
                ul{
                  li{
                    margin-bottom: 1.5rem;
                    &:last-child{
                      margin-bottom: 0;
                    }
                    .label{
                      width: 100%;

                    }
                  }
                }
              }
              ul{
                margin-top: 0;
                li{

                }
              }
              &.care_instruction{
                ul{
                  li{
                    list-style: inside;
                  }
                }
              }

              &.features, &.material{
                ul{

                  li{
                    width: 100%;
                    @include flex;
                    margin-bottom: 1rem;
                    .image{
                      width: 5rem;
                    }
                    .text{
                      width: 100%;
                      padding-left: 2rem;
                    }
                  }
                }
              }
              &.columns {
                @include flexBetween;
                flex-direction: row;
              }
              .left{
                width: 100%;
                max-width: 23.6rem;
                font-weight: bold;
              }
              .right {
                width: 100%;
                max-width: 30.5rem;
                ul {
                  li {
                    @include flexBetween;
                  }
                }
              }
              &.awards{

              }
              @media only screen and (max-width: #{$smallBreakpoint}) {
                flex-wrap: wrap;
                .left, .right {
                  max-width: inherit;
                  width: 100%;
                }
              }
              .inner:not(:last-child){
                padding-bottom: 2.5rem;
              }
            }
          }
        }
      }
    }
  } /* End .product */
}

