.page-products{
    .category-image{
      margin-bottom: 1.4rem;
    }
    .page-title{
      &-wrapper{
        @include contentInside;
      }
    }
    .product{
    }
}

.products{
  &.list{
    @include flexStart;
    flex-wrap: wrap;
    .item {
      width: 24.25%;
      margin-right: 1%;
      padding-bottom: 5rem;
      a{
        display: block;
        width: 100%;
        text-align: center;
        img{
          display: inline-block;
        }
      }
      &:nth-of-type(4n){
        margin-right: 0;
      }
    }
    .product {
      &-item{
        &-image{
          position: relative;
        }
        &-photo{
          width: 100%;
          display: inline-block;
          span {
            display: block;
            max-width: 100%;
            padding: 0 !important;
          }
          img {
            vertical-align: top;
          }
        }
        &-details{
          text-align: left;
          font-size: 1.7rem;
          margin-top: 2.1rem;
          color: $colorText;
          a{
            color: $colorText;
            text-align: left;;
          }
        }
        &-price{
          font-size: 2.4rem;
          .price{
            &-box{
              padding-top: .8rem;
              padding-bottom: .8rem;
              span.price-label {

              }
              .price-container{
                @include flex;
                align-items: center;
                justify-content: flex-start;
              }
              span.price {
                font-weight: 700;
                display: block;
              }
            }
            &-details {
             font-size: 1.2rem;
              line-height: normal;
            }
          }
        }
        &-brand {
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1.8rem;
          letter-spacing: 0.016rem;
          color: $colorSecondary;
          font-weight: 300;
        }
        &-name{
          font-weight: bold;
          font-size: 1.95rem;
          min-height: 8.1rem;
          line-height: 2.5rem;
        }
        &-actions{
          margin-top: 1rem;
        }
      }
      &.sku, &.alcoholcontent{
        font-size: 1.2rem;
        line-height: normal;
        lable{
          font-weight: bold;
        }
      }
      &-info{
        &-priceperliter{
          font-size: 1.2rem;
          line-height: normal;
        }
      }
    }
  }
}
