/* Account Sidebar*/
.account {
  .sidebar {
    .block{
      .title{
        display: none;
      }
    }
    &-main {
      padding-top: 3.5rem;
      width: 31.3rem;
      order: 1;
      a, strong {
        line-height: 2.6rem;
        display: block;
        font-size: 2rem;
      }

      a {
        padding-bottom: 1.1rem;
      }

      .current {
        padding-bottom: 1.1rem;
        color: $colorPrimary;
      }

      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        .current, a {
          padding-bottom: 0rem;
        }
      }

      .account-nav {

        @media only screen and (max-width: 1024px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .columns .column{
    width: calc(100% - 34.3rem);
  }
}

/*Sidebar*/