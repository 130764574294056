/*Account*/
.customer-account{
  &-login, &-create, &-forgotpassword, &-createpassword{
    .block-title {
      padding-bottom: 1rem;
      text-align: left;
      justify-content: flex-start;
      padding-top: 0;
    }
    .page-title{
      text-align: left;
      font-size: 2rem;
      line-height: normal;
    }
  }
}

.account {
  .page-title {
    &-wrapper {
      max-width: 100%;
      h1 {
        margin-bottom: 0;
      }
    }
  }
  .block-dashboard-orders{
    margin-top: 2rem;
  }
  .actions-toolbar {
    text-align: left !important;
    margin-top: 1rem;
  }
  .box{
    width: 32%;
    padding: 2rem {
      right: 6.5rem;
    };
    margin-right: 2%;
    border: 1px solid $colorBorder;
    position: relative;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    &-title{
      margin-bottom: 1rem;
      display: block;
      width: 100%;
      word-break: break-all;
    }
    &-actions{
      position: absolute;
      @include overlay(top, right, null, null);
      @include flexAlign(space-between, up);
      top: 2rem;
      right: 2rem;
    }
    &-content{
      padding-bottom: 0;
    }
  }
  p.toolbar-amount {
    display: none;
  }
  .order-products-toolbar.toolbar {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
    text-align: right;
    margin: 0;
    select {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: right;
    }
  }
  span.order-status, .order-date, .actions-toolbar.order-actions-toolbar a {
    @include fontSmaller;
  }
  .order-details-items.ordered {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

  }
  ul.items.order-links {
    display: none;
  }
  .limiter {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @include flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    select#limiter {
      width: 7.5rem;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
  }
  .box {
    position: relative;
    margin-bottom: 1rem;
    .box-content {
      padding-bottom: 1.5rem;
    }
  }
  .page-main{
    .order-title{
      @extend .block-title;

    }
    .block-title, .legend, .order-title {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      justify-content: flex-start;
      font-weight: bold;
      padding-top: 1rem;
      border-bottom: 0.1rem solid $colorBlack;
      padding-bottom: 1rem;

    }
    .order-title{
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        margin-bottom: 0;
      }
    }
  }

  form {
    &.form-address-edit {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      fieldset {
        width: 49%;
        .field{
          &.street{
            > .label{
              display: none;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        justify-content: center;
        fieldset {
          width: 100%;
        }
      }
    }
  }
  .column{
    .block{
      &-content{
        @include flexWrap;
        .box{
          width: 32%;
          padding: 2rem {
            right: 9rem;
          };
          margin-right: 2%;
          border: 1px solid $colorGrey;
          position: relative;
          &:nth-of-type(3n){
            margin-right: 0;
          }
          &-title{
            margin-bottom: 1rem;
            display: block;
            width: 100%;
            word-break: break-all;
          }
          &-actions{
            top: 2rem;
            right: 2rem;
          }
          &-content{
            padding-bottom: 0;
            font-size: 1.6rem;
          }
          &-order-billing-method{
            width: 100%;
            background: $colorSecondary;
            border:0;
            margin-right: 0;
            .payment-method{
              padding:{
                left:0;
                right: 0;
              };
              border-bottom: 0;
            }
          }
        }
      }
      &-dashboard{
        &-addresses{
          .block-title {
            .action {
              display: none;
            }
          }
        }
        &-orders {
          .block-content {
            > * {
              flex-basis: 100%;
            }
          }
        }
      }
      &-addresses{
        &-default{
          padding-bottom: 2.5rem;
        }
        &-list {
          .block {
            &-title {
              margin-bottom: 0;
            }
            &-content{
              padding-top: 5rem;
            }
          }
          p.empty {
            margin-top: 1rem;
            margin-bottom: 1rem;
            @include fontSmaller;
          }
        }
      }
    }
  }


  .wishlist {
    .limiter {
      margin: 0;
    }
    .product-item-info {
      @include flex;
      flex-wrap: wrap;
      > div, strong {
        width: 100%;
      }
      .price-box.price-configured_price {
        font-weight: bold;
      }
      .price-details {
        @include fontSmaller;
        padding-top: 1rem;
      }
      .product-item-inner {
        padding-top: 1.5rem;
        position: relative;
        .field.comment-box label {
          display: none;
        }
        .product-item-comment {
          width: 100%;
          min-height: 150px;
          padding: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .box-tocart {
          .field.qty {
            position: absolute;
            top: -3.6rem;
            right: 0;
            display: flex;
            align-items: center;
          }
          .control {
            margin: 0;
            margin-left: 1rem;
            input {
              width: 5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .page-wrapper .columns {
      justify-content: flex-end;
      .products.wrapper.grid.products-grid ol, .widget.elementor-widget.product-grid ol {
        justify-content: flex-start;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .page-wrapper .columns {
      .products.wrapper.grid.products-grid ol, .widget.elementor-widget.product-grid ol {
        justify-content: center;
      }
    }
  }
}
.customer-account {

  &-create {
    h1 {

    }

    .actions-toolbar {
      display: flex !important;
      width: 100%;
    }

    form#form-validate {
      @include flexWrap;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .fieldset {
        width: 49%;
        &.info{
          margin-left: 2%;
        }
        legend.legend {
          font-size: 1.8rem;
          font-weight: bold;
          padding-bottom: 1rem;
        }

        @media only screen and (max-width: 1280px) {
          width: 100%;
        }
      }
    }
  }
}

.additional {
  &_info{
    justify-self: flex-end;
  }
  &-addresses {

  }
}

/*Login*/
.login-container {
  @include flexBetween;
  align-items: flex-start;

  .block {
    width: 49%;
    border: none;
    display: block;
    .primary {
      display: block;
    }
    .block-content {
      .actions-toolbar {
        margin-top: 1.5rem;
      }
    }
    form fieldset {
      label {
      }
    }
  }
}
/*Passwort*/
.password.required {

  .control {
    display: flex;
    flex-direction: column;
    #password {
      order: 1;
    }

    #password-error {
      color: $colorError;
      margin: 0.5rem 0;
      order: 3;
    }

    #password-strength-meter-container {
      background-color: $colorError;
      padding: 1rem 0 1rem 1rem;
      order: 2;
      color: $colorWhite;
      margin-bottom: 1rem;
      #password-strength-meter {
        line-height: 1;
      }
      &.password-very-strong {
        background-color: $colorSuccess;
      }
      &.password-strong {
        background-color: $colorSuccess;
      }
      &.password-medium {
        background-color: $colorInfo;
      }
      &.password-weak {
        background-color: $colorError;
      }
      &.password-error {
        background-color: $colorError;
      }
    }
  }
}
