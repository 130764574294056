@mixin contentMedium{
  max-width: $mediumMaxBreakpoint;
  margin: 0 auto;

  @media only screen and (max-width: #{$mediumMaxBreakpoint}) {
    max-width: $mediumBreakpoint;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: #{$mediumBreakpoint}) {
    padding-left: 0;
    padding-right: 0;
  }
}
@mixin overlay($directionV: top, $directionH: left, $width: 100%, $height: 100%, $position: absolute, $right: false, $bottom: false, $top: false, $left: false){
  position: $position;
  height: $height;
  width: $width;

  @if $directionV == top {
    top: 0;
  } @else if $directionV == bottom {
    bottom: 0;
  } @else if $top {
    top: $directionV;
  } @else if $bottom {
    bottom: $directionV;
  } @else {
    top: $directionV;
  }

  @if $directionH == left {
    left: 0;
  } @else if $directionH == right {
    right: 0;
  } @else if $left {
    left: $directionH;
  } @else if $right {
    right: $directionH;
  } @else {
    left: $directionH;
  }

}
@mixin flexAlign($directionH: null, $directionV: null, $flex: null) {
  @if ($flex == flex) {
    @include flex;
  }
  align-items: $directionV;
  justify-content: $directionH;
}
@mixin contentInside{
  max-width: 100%;
  margin: 0 auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  @media only screen and (max-width: #{$maxBreakpoint}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: #{$mediumMaxBreakpoint}) {
    max-width: $mediumBreakpoint;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: #{$mediumBreakpoint}) {
  }
}

@mixin contentSmall{
  max-width: 127rem;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: #{$maxBreakpoint}) {
    max-width: $mediumBreakpoint;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media only screen and (max-width: #{$mediumMaxBreakpoint}) {
    max-width: $mediumBreakpoint;

  }
  @media only screen and (max-width: #{$mediumBreakpoint}) {
    width: 100%;
  }
}
@mixin rotateCCW10 {
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
@mixin rotateCCW15 {
  -moz-transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
@mixin defaultTrans {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
@mixin shadow {
  box-shadow: 0 0 4px 0 $colorLighterGrey;
}
@mixin defaultFastTrans {
  transition: all 250ms ease;
}
@mixin transition {
  transition: all 500ms ease;
}
@mixin flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@mixin flexWrap {
  @include flex;
  flex-wrap: wrap;
}
@mixin flexCenter {
  @include flex;
  align-items: center;
  justify-content: center;
}
@mixin flexBetween {
  @include flex;
  justify-content: space-between;
}
@mixin flexEnd {
  @include flex;
  justify-content: flex-end;
}
@mixin flexStart {
  display: flex;
  align-items: flex-start;
}
@mixin flexColumn {
  @include flex;
  flex-direction: column;
}
@mixin background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@mixin icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  color: $colorBlack;
  content: '';
}
@mixin border1 {
  border: 1px solid #c8c8c8;
}

@mixin border2 {
  border: 1px solid #f4f4f4;
}