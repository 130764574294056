.mage-error {
  color: $colorPrimary;
}

.message {
  padding: 1rem 2.5rem;
  display: block;
  margin: 0 auto;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;

  font-size: 1.4rem;
  &.error {
    background: $colorError;
    color: $colorWhite;

  }
  &.success {
    background: $colorButton;
    color: $colorWhite;
  }
  &.info {
    background: $colorGrey;
    color: $colorWhite;
  }
  &.empty {
    background: $colorError;
    color: $colorWhite;
  }
  &.warning {
    background: $colorButton;
    color: $colorWhite;
  }
  &.notice {
    background: $colorPrimary;
    color: $colorWhite;
  }
  &.success,
  &.notice,
  &.warning,
  &.info,
  &.empty,
  &.error {
    a {
      color: $colorWhite;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: $colorWhite;
        text-decoration: underline;
      }
    }
  }
}
.cms-home{
  .messages{
    margin-bottom: 0;
  }
}
.checkout-index-index{
  .messages{
    width: 100%;
    .message{
      &.error{
        margin: 0;
        width: 100%;
        max-width: 95.2rem;
        margin-bottom: 20px;
      }

    }
  }
  .message{
    &.notice{
      margin-bottom: 3rem;
    }
  }
}
.field-error, .mage-error {
  color: $colorError;
  font-size: 1.4rem;
  width: 100%;
}