footer{
    .footer{
        &-top, &-main{
            @include contentInside;
        }
        &-boxes{
            background: $colorGrey;
            .inner-container{
                @include contentMedium;
                padding-top: 12rem;
                padding-bottom: 12rem;
            }
            &-block{
                text-align: center;
                color: $colorSecondary;
                width: 29%;
                h2{
                    font-size: 3rem;
                    line-height: normal;
                    padding-bottom: 2.7rem;
                }
                i{
                    font-size: 4rem;
                    color: $colorSecondary;
                    margin-bottom: 1.8rem;
                    &.fa-shipping-fast{
                        font-weight: 300;
                    }
                }
                p{
                    font-size: 1.8rem;
                    font-weight: 300;
                }
                a{
                    margin-top: 1rem;
                    font-weight: bold;
                    display: inline-block;
                    width: 100%;
                    color: $colorSecondary;
                }

            }

        }
        &-content{
        }
        &-top{
            background: $colorSecondary;
            padding-top: 2rem;
            padding-bottom: 2rem;
            .inner-container{
                border-bottom: 1px solid rgba($colorWhite, 0.1);
            }
            &-left{
                @include flex;
                width: 75%;
            }
            &-right{
                width: 25%;
            }
            &-pyramid{
                @include flex;
                width: 100%;
            }
            ul{
                @include flexWrap;
                width: 100%;
                justify-content: center;
                li{
                    width: 100%;
                    @include flex;
                    align-self: center;
                    h4{
                        padding-bottom: 0;
                        margin-bottom: 1rem;
                        margin-top: 2rem;
                        font-size: 1.6rem;
                        color: $colorGrey;
                    }
                    a{
                        color: $colorGrey;
                        font-weight: bold;
                        display: block;
                        padding: 0;
                        font-size: 1.6rem;
                        padding-left: 1rem;
                        text-transform: uppercase;

                    }
                }
            }
            &-info{
                width: auto;
                max-width: 30%;
            }
        }
        &-bottom{
            .section{
                color: $colorText;
                line-height: normal;
                ul{
                    li{
                        padding: .4rem 0;
                    }
                }
                a{
                    color: $colorText;
                    display: inline-block;
                    width: auto;
                    line-height: normal;
                }
            }
            .inner-container{
                @include contentInside;
                border-top: 1px solid rgba($colorWhite, 0.2);
            }
            &-member{
                @include flex;
                align-items: center;
                span{
                    &:before{
                        display: block;
                    }
                }
            }
            &-copyright{
                font-size: 1.2rem;
            }
            &-left{
                width: auto;
                padding: 2rem 0 4rem;
                @include flex;
                color: $colorText;
            }
            &-contact{
                color: $colorLighterGrey;
                @include flex;
                padding-right: 8rem;
                a{
                    color: $colorLighterGrey;
                }
                ul{
                    width: 50%;
                }
            }
            &-links{
                padding-right: 12rem;
            }
            &-member{
                padding-left: 3rem;
                span{
                    @include flex;
                    align-items: center;

                    font-size: 2.4rem;
                    font-weight: bold;

                    &:after{
                        @include fontIcon;
                        color: $colorWhite;
                        content: "\f105";
                        margin-left: 1rem;
                        line-height: normal;
                        margin-top: .3rem;



                    }
                }
            }
            &-right{
                @include flex;
                align-items: center;
                .section{
                    padding-right: 0;
                    font-size: 1.2rem;

                }
                ul{
                    @include flex;
                    li{
                        &:first-child{

                            margin-right: .5rem;
                            a{
                                padding-right: .5rem;
                                border-right: 1px solid $colorText;
                            }
                        }
                    }
                }
                a{
                    color: $colorText;
                }
            }
        }
        &-main{
            background: $colorGrey;
            padding-top: 2rem;
            padding-bottom: 2rem;
            .section{
                width: 23%;
                text-align: left;
                margin-right: 2%;
                a{
                    color: $colorLighterBrown;
                }
                ul{
                    li{
                        color: $colorLighterBrown;
                        font-size: 1.2rem;
                        margin-bottom: .5rem;
                        h3{
                            color: $colorLighterBrown;
                            font-size: 1.6rem;
                            padding-bottom: 1.5rem;
                            margin-bottom: 1.5rem;
                            margin-top: 0;
                            border-bottom: 1px solid $colorLighterBrown;
                            font-weight: normal;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            &-definitions{
                padding-top: 2rem;
            }
            nav{
                @include contentMedium;
                > ul{
                    padding: 5rem 0 0;
                    @include flexWrap;
                    >li{
                        width: 25%;
                        padding-bottom: 5rem;
                        > a{
                            font-size: 2.4rem;
                            font-weight: 700;
                            margin-bottom: 1.5rem;
                        }
                    }
                }
                ul{
                    li{
                        a{
                            color: $colorWhite;
                            display: inline-block;
                            width: 100%;
                            padding: .4rem 0;
                            line-height: normal;
                        }
                    }
                }
            }
            &-left{
                width: 75%;
                @include flex;
            }
            &-right{
                width: 25%;
                .section{
                    width: 100%;
                    margin-right: 0;
                }
            }
            &-info{
                &-payments{
                    ul{
                        @include flexWrap;
                        align-items: center;
                        padding-top: 3rem;
                        li{
                            img{
                                margin-right: .5rem;
                            }
                            &:first-child{
                                width: 100%;
                                img{
                                    max-width: 8rem;
                                }
                            }
                            &:nth-of-type(2), &:nth-of-type(3){
                                img{
                                    max-width: 5rem;
                                }
                            }
                            &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6){
                                img{
                                    max-width: 3rem;
                                }
                            }
                        }
                    }
                }
            }
            &-newsletter{
                &-icons{
                    margin-top: 2rem;
                    ul{
                        @include flex;
                        li{
                            margin-right: 1rem;
                            a{
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .inner-container{
        @include flex;
        justify-content: space-between;
    }
}
