.block-search {
  .block{
    &-title{
      padding: 0;
      margin: 0;
      border:0;
      cursor: pointer;
      &:before{
        content: "\f002";
        @include fontIcon;
        font-size:  1.8rem;
      }
      strong{
        display: none;
      }
    }
    &-content{
      display: none;
    }
  }
  &.active{
    .block{
      &-title{
        position: relative;
        &:before{
          content: "\f00d";
          font-size: 2.5rem;
          font-weight: 300;
        }
      }
      &-content{
        width: 100%;
        background: $colorDarkBrown;
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        border-top: 2px solid $colorSecondary;
        z-index: 99;
        form{
          @include flex;
          padding: 1rem;

          .search{
            width: 100%;
            input{
              height: 100%;
              border:0;
            }
          }
          .action{
            &.search{
              padding:1.5rem;
              height: 100%;
              border:0;
              margin-right: 0;
              display: block;
              background: $colorWhite;
              &:before{
                content: "\f002";
                @include fontIcon;
                color: $colorSecondary;

              }
              span{
                display: none;
              }
            }
          }
          .control{
            height: 100%;
          }
          .label{
            display: none;
          }
        }
      }
    }
  }
}