button{
  cursor: pointer;
}
.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
  }
  .action {
    cursor: pointer;
    padding: 1rem 1.5rem;

    margin-right: 2rem;
    font-size: 1.4rem;
    font-weight: bold;

    border: none;
    line-height: 1;
    @include defaultFastTrans;

    &.primary, &.tocart.primary, &.allow  {
      color: $colorWhite;
      background-color: $colorGreen;
      border: 1px solid $colorGreen;
      &:hover {
        @include defaultTrans;
      }
      &.tocart.disabled {
        border-color: $colorSuccess;
        background-color: $colorSuccess;
      }
      @media only screen and (max-width: #{$mediumSmallBreakpoint}) /* 768px */ {
        width: 100%;
      }
    }
    &.secondary, &.action-edit-address , &.action.edit-address-link, &.tocategory.secondary, &.totag.secondary, &.action-select-shipping-item, &.back, &.remind, &.tocart, &.reload, &.clear, &.update, &.action-hide-popup, &.action-show-popup, &.action-update, &.action-cancel, &.share {
      color: $colorPrimary;
      background-color: $colorWhite;
      border: 1px solid $colorPrimary;
      text-align: center;
      &:hover {
        @include defaultTrans;
      }
    }
    @media only screen and (max-width: #{$mediumBreakpoint}) {
      margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
      letter-spacing: 0;
      line-height: normal;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: center;
    }
    &.edit, &-edit{
      border: 0;
      font-size: 0;
      line-height: 0;
      padding: 0;
      background: transparent;
      > span {
        display: none;
      }
      &:before {
        @include fontIcon(1.6);
        color: $colorPrimary;
        display: inline-block;
        content: "#{$iconEdit}";
      }
    }
  }
  .primary + .secondary{
    @media only screen and (max-width: #{$mediumBreakpoint}) {
      margin-left: 2rem;
    }
    @media only screen and (max-width: #{$mobileBreakpoint}) {
      margin-left: 0;
      width: 100%;
    }
  }

}
#minicart-content-wrapper .secondary .action, a.action.print, .block.block-dashboard-orders .block-title.order a.action.view {

  padding:1.5rem 4rem;
  font-size: 1.2rem;
  margin-right: 2rem;
  color: $colorBlack;
  background-color: transparent;
  border: 0.1rem solid black;
  @include defaultFastTrans;

}
.block.block-dashboard-orders .block-title.order a.action.view {
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  margin-right: 0;
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}
.top-container .overlay .action{
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: .7rem 1.5rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;

  &:hover {
    margin: 0;
    background-color: $colorWhite;
  }
}
.page-main  .actions-toolbar {
  @include flex;

  .action{
    display: inline-block;
  }
  @media only screen and (max-width: #{$mediumBreakpoint}) {
    flex-wrap: wrap;
    .action{
      width: 100%;
    }

    .primary{
      margin-bottom: .5rem;
    }
  }
  @media only screen and (max-width: #{$mobileBreakpoint}) {
    .primary, .secondary{
      width: 100%;
    }
  }

}
.page {
  &-main, &-header {
    .action {
      &.delete, &-delete{
        text-decoration: none;
        max-width: inherit;
        min-height: inherit;
        background: $colorPrimary;
        color: $colorWhite;
        display: inline-block;
      }
    }
  }
  &-main{
    .action{
      & + .action{
        margin-left: 2rem;
      }
      &.delete, &-delete,
      &.edit, &-edit,
      &.change-password,
      &-change-password{
        border: 0;
        font-size: 0;
        line-height: 0;
        padding: 0;
        background: transparent;
        margin-right: 0;
        display: inline-block;
        > span {
          display: none;
        }
        &:before {
          line-height: normal;
          @include fontIcon(1.6);
          color: $colorPrimary;
          display: inline-block;
        }
      }
      &.delete, &-delete{
        &:before {
          content: "#{$iconTrash}";
        }
      }
      &.edit, &-edit{
        &:before {
          content: "#{$iconEdit}";
        }
      }
      &.change-password,
      &-change-password{

        &:before {
          content: $iconKey;
        }
      }
    }

  }
}



.widget.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
input.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
ul.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: #{$mediumBreakpoint}) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }

    }
  }
}
