.webcrossing-widget-image-slider{
  .block-content{
    .splide {
      &__pagination{
        display: none;
      }
      &__arrows {
        button.splide__arrow.splide__arrow {
          &--prev,  &--next{
            margin-top: 8rem;
            transition: all ease .2s;
            position: absolute;
          }
          &--prev {
            left: 5rem;
            &:before{
              content: url($imageDir + "slider-arrow-left.png");
            }
          }

          &--next {
            right: 5rem;
            width: 1.8rem;
            height: 7.5rem;
            &:before{
              content: url($imageDir + "slider-arrow-right.png");
            }
            &:hover {

            }
          }
        }
      }
    }
    ul{
      li{
        position: relative;


        overflow: hidden;
        .image{

          width: 100%;
          @include flex;
          align-items: center;
          justify-content: center;
          img{

            min-height: 100%;
            width: auto;
            height: auto;

          }
        }
        .info{
          max-width: $mediumContent;
          position: absolute;
          top: 50%;
          width: auto;
          height: auto;
          @include flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          &.right{
            right: 5%;
            transform: translate(-50%, -50%);
          }
          &.left{
            left: 5%;
            transform: translate(15%, -50%);
          }
          @media only screen and (max-width: #{$smallBreakpoint}) {
            max-width: 90%;
          }
          >*{
            width: 100%;
          }
          h2{
            color: $colorWhite;
            padding-top: 0;
            text-align: left;
            font-weight: normal;
            font-size: 6.75rem;
            line-height: 6rem;
            padding-bottom: 0;
            width: auto;
          }
          h3{
            width: auto;
            font-weight: bold;
            color: $colorWhite;
            font-size: 8rem;
            line-height: 8rem;
          }
          > p{
            letter-spacing: 1.25px;
            font-size: 1.87rem;
            text-align: left;
          }
          .actions{
            @include flex;
            padding-top: 1.2rem;
            a{
              color: $colorWhite;
              border:0;
              padding-top: 1rem;
              padding-bottom: 1rem;
              background: $colorPrimary;

            }
          }
          &.white{
            color: $colorWhite;
            h2{
              color: $colorWhite;
            }
          }
        }
      }
    }
  }
}