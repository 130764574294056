@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans-regular.eot');
    src: url('../../fonts/opensans-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-regular.woff2') format('woff2'),
    url('../../fonts/opensans-regular.woff') format('woff'),
    url('../../fonts/opensans-regular.ttf') format('truetype'),
    url('../../fonts/opensans-regular.svg#opensans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans-semibold.eot');
    src: url('../../fonts/opensans-semibold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-semibold.woff2') format('woff2'),
    url('../../fonts/opensans-semibold.woff') format('woff'),
    url('../../fonts/opensans-semibold.ttf') format('truetype'),
    url('../../fonts/opensans-semibold.svg#opensans-semibold') format('svg');
    font-weight: 600;
    font-style: normal;

}
@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans-bold.eot');
    src: url('../../fonts/opensans-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-bold.woff2') format('woff2'),
    url('../../fonts/opensans-bold.woff') format('woff'),
    url('../../fonts/opensans-bold.ttf') format('truetype'),
    url('../../fonts/opensans-bold.svg#opensans-bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../../fonts/opensans-extrabold.eot');
    src: url('../../fonts/opensans-extrabold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/opensans-extrabold.woff2') format('woff2'),
    url('../../fonts/opensans-extrabold.woff') format('woff'),
    url('../../fonts/opensans-extrabold.ttf') format('truetype'),
    url('../../fonts/opensans-extrabold.svg#opensans-extrabold') format('svg');
    font-weight: 900;
    font-style: normal;

}
@font-face {
    font-family: 'slick';
    src: url('../../fonts/slick.eot');
    src: url('../../fonts/slick.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/slick.woff') format('woff'),
    url('../../fonts/slick.ttf') format('truetype'),
    url('../../fonts/slick.svg#slick') format('svg');
    font-weight: normal;
    font-style: normal;

}


@mixin font{
    font-family: 'OpenSans';
}

@mixin fontIcon($sizeValue: 2.2, $iconWeight:  400) {
    font-family: "Font Awesome 5 Pro";
    font-weight: $iconWeight;
    font-size: $sizeValue + rem;
}
@mixin fontSmaller {
    font-size: 1.4rem;
    line-height: 2.2rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.3rem;
    }
}
@mixin fontDefault {
    @include font;
    font-size: 1.6rem;
    line-height: 2rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.4rem;
    }
}
@mixin fontTable{
    font-size: 1.5rem;
    line-height: 2rem;
}
@mixin fontMedium {
    font-size: 1.6rem;
    line-height: 2.2rem;
}
@mixin fontLarger {
    font-size: 2.0rem;
    @media only screen and (max-width: 480px) {
        font-size: 1.6rem;
    }
    @media only screen and (max-width: 420px) {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
}
@mixin specialFont {
    @include font;
    font-weight: normal;
    text-align: left;
}
@mixin letterSpacing {
    letter-spacing: 0.09rem;
    @media only screen and (max-width: 480px) {
        letter-spacing: 0.05rem;
    }
}


/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
    font-weight: bold;
}

@mixin fontPriceProductsSpecial {
    font-weight: bold;
    color: $colorText;
}

@mixin fontPriceProductsSale {
    font-weight: bold;
    text-decoration: line-through;
}

@mixin fontPriceProductSale {
    font-weight: bold;
    text-decoration: line-through;
}

body {
    @include fontDefault;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include specialFont;
    text-align: left;
    color: $colorText;
    word-break: break-word;
    hyphens: auto;
}

h1 {
    font-size: 4.8rem;
    line-height: 6.8rem;
    padding-bottom: 0;
    @include font;
    font-weight: bold;
    &:not(:first-child) {
        padding-top: 0;
    }
    &.noPadding {
        padding-bottom: 0;
    }
    @media only screen and (max-width: 768px) {
        font-size: 3.4rem;
        line-height: 4.6rem;
    }
}

h2 {
    font-size: 6rem;

    line-height: 6rem;
    padding-bottom: 2.3rem;
    @include font;
    font-weight: bold;
    padding-top: 7.2rem;
    color: $colorGrey;
    &:not(:first-child) {
        padding-top: 0;
    }
    @media only screen and (max-width: 768px) {
        font-size: 3rem;
        line-height: normal;
    }
}

h3 {
    font-size: 2rem;
    line-height: 3.1rem;
    letter-spacing: 0;
    font-weight: bold;
    margin-top: .5rem;
    margin-bottom: 1rem;
    &:not(:first-child) {
        padding-top: 0;
    }
    @media only screen and (max-width: 768px) {
        font-size: 1.7rem;
        line-height: 2.4rem;
    }
}

h4 {
    @include fontLarger;
    @include specialFont;
    padding-bottom: 2.8rem;
    @media only screen and (max-width: 480px) {
       padding-bottom: 0.9rem;
    }

}

a {
    color: $colorText;
    text-decoration: none;
}

b,
strong {
    font-weight: 700;
}

ol,
ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
}
