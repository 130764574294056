.webcrossing {
  &-widget {
    &-accordion{

      margin-bottom: 7rem;
      #accordion{
        > ul{
          > li{
            border: 1px solid rgba($colorSecondary, .2);
            border-radius: 4px;
            margin-bottom: 5px;
            table{
              border:0;
              width: auto;
              margin-bottom: 2rem;
              tr{
                td{
                  border:0;
                  background-color: rgba($colorPrimary, .25);
                  vertical-align: top;
                  font-size: 1.7rem;
                  padding: 1rem 1.5rem;
                  &:first-child{
                    font-weight: bold;
                  }
                }

                &:nth-of-type(2n){
                  td{
                    background-color: rgba($colorPrimary, .03);
                  }

                }
              }
            }
          }
        }
      }

      .active{
        .accordion{
          &-title{
            &:after{
              transform: rotate(180deg);
            }
          }

        }
      }
      .accordion{
        &-title, &-item-hidden-part{
          padding: 1.5rem 2.9rem;

        }


        &-title{
          text-align: left;
          color: $colorPrimary;
          font-size: 2rem;
          line-height: normal;
          cursor: pointer;
          font-weight: bold;
          position: relative;
          &:after{
            @include transition;
            position: absolute;
            content: "\f078";
            @include fontIcon;
            font-weight: 300;
            color: $colorPrimary;
            right: 2rem;
            top: 0;
            height: 100%;
            width: 1rem;
            @include flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
          }

        }
        &-item-hidden-part{
        display: none;
          margin-bottom: 5px;
          color: $colorSecondary;
          font-size: 1.6rem;
          line-height: 2rem;
          p{
            margin-bottom: 2rem;
          }
          ul{
            margin-bottom: 2rem;
            padding-left: 2rem;
            li{
              list-style: disc;
              margin-bottom: 4rem;
            }
          }
          .title{
            font-weight: bold;
            margin-bottom: 1rem;
          }
        }
      }
    }
    &-product{
      &-teaser{
        @include contentInside;
        padding-top: 2.5rem;
      }
      &-sliderwithbanner{
        padding: 6.2rem 0;
        .block {
          &-title {

            padding: 0;
            position: relative;
            p {
              max-width: $mediumContent;
              text-align: center;
              padding: 52px;
              color: $colorSecondary;
            }
            .image {
              padding-top: calc(248.44 / 790.34 * 100%);
              width: 100%;
              position: relative;
              overflow: hidden;
              @include flex;
              align-items: center;
              justify-content: center;

              img {
                position: absolute;
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                top: 50%;
                transform: translate(-50% , -50%);

              }


            }
          }
          &-content{
            .actions{
              display: block;
              text-align: center;
              width: 100%;
              margin-top: 3rem;
              a{
                display: inline-block;
                background: $colorPrimary;
                color: $colorWhite;
              }
            }
          }
        }
      }
    }
  }
}
.cms-page-view{
  .page-title{
    text-align: center;
  }
}