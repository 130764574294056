/**** Table *****/
/* Table Simple */
table {
  border-spacing: 0 !important;
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      @include fontTable;
      line-height: 1;
      font-weight: bold;
      text-align: center;
      &:first-child {
        text-align: left;
        border-bottom: 1px solid $colorSecondary;
        th{
          color: $colorPrimary;
          font-weight: bold;
        }
      }
    }
  }
  tfoot{
    width: 100%;
    display: table;
    background: $colorSecondary;
    margin-top: 5rem;
    tr{
      width: 100%;
      border-bottom: 1px solid $colorWhite;
      @include flex;
    }
    th{
      width: 100%;
    }
    .amount{
      text-align: right;
    }
    .mark{
      font-weight: 600;
      color: $colorPrimary;
      padding: 1rem;
      text-align: left;
    }
  }
  th {
    padding: 1rem 0;
    text-align: left;
    @include fontTable;
    color: $colorPrimary;
    font-weight: 500;
    &.subtotal {
      text-align: center;
    }
    &.price{
      text-align: center;
    }
    &.col{
      padding: 1rem;

      font-weight: 500;
      white-space: nowrap;
      &.action {
        padding: 0;
        width: auto;
        border:0;
        border-radius: 0;
        background: $colorPrimary;
        height: 4rem;
        &:hover{
          background: $colorPrimary;
        }
      }
    }
    &.item {
      text-align: left;

    }

  }
  tbody{
    width: 100%;
    padding: .6rem 0;
    border-bottom: 0.1rem solid rgba($colorBorder, .2);
    position: relative;
  }
  .control {
    &.size {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding-right: 8.2rem;
    }
  }
  td {
    padding: 0;
    text-align: left;
    @include fontTable;
    &.amount{
      padding: 1rem 0;
    }
    &.col {
      position: relative;
      text-align: left;
      padding: 1rem 1rem 1.1rem;
      display: table-cell;
      vertical-align: middle;
      .cart{
        &-price{
          font-weight: normal;
          @include fontTable;
        }
      }
      &.actions{
        @include flex;
        a{
          display: inline-block;
        }
      }
      .product {
        &-notice-img{
          margin-right: 1rem;
          max-width: 3rem;
          line-height: 0;
        }
        &-item {
          &-notice{
            padding: 1rem 0;
            p{
              @include fontTable;
            }
          }
          &-details {
            display: table-cell;
            padding-left: 3rem;
            vertical-align: middle;
            text-align: left;
            >span{
              display: block;
            }
          }

          &-brand {
            width: 100%;
            display: block;
            @include fontTable;
            text-transform: uppercase;

          }
        }

        strong {
          line-height: 1;
        }
      }
      &.action{
        padding: 0;
        border:0;
        display: table-cell;
        &:hover{
          background: transparent;
          color: initial;
        }
        .action{
          text-align: center;
          display: inline-block;
          &-delete{
            &:before{
              display: inline-block;
            }
          }
          span{
            height: auto;
            position: inherit;
            overflow: initial;
            padding-top: .3rem;
            display: inline-block;
            width: 100%;
            @include fontTable;
            font-weight: 300;
            color: $colorPrimary;
          }
          &s-toolbar{
            justify-content: flex-end;
            a{
              display: inline-block;
            }
          }
        }
      }
    }
    &.item {
      padding-left: 0;
      display: block;
      width: 100%;
      a {
        display: table-cell;
        width: auto;
        span {
          position: relative;
          display: block;

          img {
            position: absolute;
            top: 0;
            left: 0;

          }
        }
      }

    }
    &.price {
      text-align: center;

    }
    &.qty {

      .qty{
        text-align: left;
        background: transparent;
        margin: 0;
        padding: 0.8rem 1.0rem;
        min-width: 75px;
      }
      .item{
        @include flex;
      }
      label{
        display: inline-block;
      }
      .label {
        display: none;
      }

      input {
        display: inline-block;
        @include fontDefault;
        font-weight: normal;
        cursor: pointer;
        padding: 1.2rem 1.8rem;

        border: 1px solid $colorBorder;
        //color: $colorSecondary;
      }
    }
    &.subtotal {
      text-align: center;
      font-weight: bold;
      font-size: 2.1rem;
      .cart{
        &-price{
          font-weight: normal;
          @include fontTable;

        }
      }
    }

  }

  /* Table Account */
  .account &{
    .table-caption {
      display: none;
    }
    td{
      padding: .7rem 1rem;
      &.qty{

        .title{
          font-size: initial;
          font-weight: initial;
          display: initial;
          @include fontTable;
          border:0;
          padding-bottom: initial;
        }
      }
      &.actions {
        .delete{
          margin-left: 1.5rem;
        }
      }
    }
    th{


    }
    &.table-order-items {
      margin-bottom: 1.5rem;
      tr {
        td, th {

          &.qty {

            padding: .7rem 1rem;
            ul.items-qty {
              .item {
                padding-right: 1rem;
                @include flexCenter;
                justify-content: flex-start;
                .title{
                  @include fontTable;
                  font-weight: 500;
                  padding: 0;
                  border:0;
                  margin-right: 1.2rem;
                  word-break: normal;
                  hyphens: none;
                }
              }
            }
          }
          &.subtotal {
            text-align: right;
            width: 15%;
          }
        }
      }
      tfoot {
        margin-top: 2rem;
        display: block;
      }
    }
  }

}
.additional-addresses, .orders-recent{
  width: 100%;

}
/* Table .inner-container */
.inner-container{
  table{
    margin-top: 2.5rem;
    thead {
      th {
        text-align: left;
        font-weight: 500;
        border-bottom: 1px solid $colorBorder;
        padding: .7rem 0;
      }
    }
    tbody{
      border-bottom: 0;
      td{
        text-align: left;
        border-bottom: 1px solid $colorBorder;
        padding: .7rem 0;
      }
    }
  }
}

/*** RESPONSIVE ***/
@media only screen and (max-width: #{$maxBreakpoint}) /* 1440px */ {
  table{
    .additional-addresses &{
      tbody{
        border-bottom: 0;
      }
      tr{
        padding-top: 2rem;
        th{
          padding-top: 1rem;
        }
        td.col {
          width: 100%;
          @include flex;
          padding: {
            bottom: 0;
            left: 0;
          };
          &[data-th]::before {
            width: auto;
            margin-right: 1rem;
          }
          .price {
            margin-top: 0;
          }
          a {
            margin-top: 0;
          }
          &.actions{
            a{
              padding: 0;
            }
          }
        }
      }
      thead {
        display: none;
      }
      td{
        &.col[data-th] {
          &:before {
            @include fontDefault;
            content: attr(data-th) ":";
            width: 100%;
            @include fontTable;
            padding: 0 0 1rem;
            text-align: left;
            font-weight: bold;
            display: block;
            color: $colorPrimary;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: #{$mediumBreakpoint}) /* 1024px */ {
  table{
    display: flex;
    flex-wrap: wrap;
    tr{
      @include flex;
      flex-wrap: wrap;
      &.item-info{
        justify-content: space-between;
      }
      th {
        display: none;
      }
      td{
        text-align: left;
        &.amount{
          width: 100%;
          @include flex;
          &[data-th] {
            &:before {
              content: attr(data-th) ":";
              width: 100%;

              padding: 0 2rem 1rem 0rem;
              padding-right: 0;
              text-align: left;
              @include fontTable;
              font-weight: bold;
              text-transform: uppercase;
              display: block;
            }
          }
          span{
            &.price{
              justify-content: flex-end;
            }
            width: 100%;
            @include flex;
            &[data-th] {
              &:before {
                content: attr(data-th) ":";
                width: 100%;

                padding: 0 2rem 1rem 0rem;
                padding-right: 0;
                text-align: left;
                @include fontTable;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
              }
            }
          }
        }
        &.col[data-th] {
          &:before {
            @include fontDefault;
            content: attr(data-th) ":";
            width: 100%;
            @include fontTable;
            padding: 0 0 1rem;
            text-align: left;
            font-weight: bold;
            display: block;
            color: $colorPrimary;
          }
        }
        &.col{
          width: auto;
          text-align: left;
          padding-top: 0;
          padding-bottom: 3rem;
          padding-right: 1rem;
          position: inherit;
          > * {
            display: block;
          }
          .actions-toolbar{
            position: absolute;
            padding: 0;
            top: 4rem;
            right: 0;
            margin-right: 0;
            margin-top: 0;
            @include flexCenter;
            a{
              display: inline-block;
            }
          }


          &.action{
            top: 4rem;
          }
        }
        &.subtotal {
          padding-right: 0;
        }
        &.item {
          width: 100%;
          padding-right: 0;
          padding-top: 0;
          @include flexWrap;
          > a {
            width: auto;
            display: inline-block;
          }
          .product {
            &-item {
              &-photo{
                min-height: inherit;
              }
              &-details {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 10rem);
              }
            }
          }
        }
        &.qty{
          padding-top: 0
        }
      }
    }
    thead {
      width: 100%;
      display: none;
    }
    tbody{

      &.cart.item{
        position: relative;
        @include flexColumn;
        flex-direction: column-reverse;
      }
    }
    .account &{
      tbody{

        &:first-child{
          padding-top: 0;
        }
      }
      tr{
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $colorSecondary;
        &:last-child{
          border-bottom: 0;
          margin-bottom: 0;
        }
        th{
          padding-top: 1rem;
        }
        td.col {
          width: 100%;
          @include flexWrap;
          padding: 0{
            top: 0;
            bottom: 1rem;
          };
          &[data-th]::before {
            width: 100%;
            margin-right: 1rem;
            padding: 0;
          }
          &.name{
            strong{
              font-weight: normal;
            }
          }
          .price {
            margin-top: 0;
          }
          &.qty, &.price{
            padding: 0{
              top: 0;
              bottom: 1rem;
            };
          }
          a {
            margin-top: 0;
          }
          &.actions{
            a{
              padding: 0;
            }
          }
        }
      }
    }
    &.table {
      &-order-items {
        tr td {
          strong {
            color: $colorPrimary;
          }

          &.price {
            padding: .7rem 1rem;
          }
        }
      }
      &-additional-addresses-items{
        tr{
          padding-bottom: 2rem;
          border-bottom: 1px solid $colorBorder;
          margin-bottom: 2rem;
          &:last-child{
            border:0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    &.cart{
      tr td.col[data-th]::before{
        text-transform: uppercase;
      }
    }
  }
  .inner-container{
    table{
      thead{
        display: none;
      }
      tbody{
        border-bottom: 0;
      }
      tr{
        padding-top: 2rem;
      }
      td{
        &.col{
          padding-left: 0;
          padding-bottom: 1rem;
          &[data-th]::before{
            padding-bottom: 0;
          }
        }
      }
    }
  }
}/* END 1024px */

@media only screen and (max-width: #{$mobileBreakpoint}) /* 480px */ {
  table {
    tr {
      &.item-actions{
        td{
          .actions-toolbar{
            bottom: inherit;
            top: 1rem;
            flex-wrap: nowrap;
            @include flexCenter;
            .action{
              padding: 0;
            }
            .action + .action{
              margin-top: 0;
              padding-left: 1rem;
            }
          }
        }
      }
      td {
        &.col {
          &.item{
            .product-item-details{
              width: 100%;
              padding-left: 0;
              padding-top: 3rem;
            }
          }
          &.price {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}/* END 480px */



