*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 62.5%;
}
img{
  max-width: 100%;
  height: auto;
  line-height: normal;
}
body {
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 3.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorGrey;
}
.fal {
  font-weight: 400;
}

::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}

body.cms-no-route .columns{
  a {
    font-weight: bold;
  }
}
.column-main {
  position: relative;
}
$animate: all 0.2s ease-in-out;