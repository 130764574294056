@import "utils/variables";
@import "utils/helper";
@import "base/default";
@import "base/typography";



@media only screen and (max-width: #{$maxBreakpoint})/* 1440px */ {
    header {
        .header {
            &-main {
                &-logo{
                    padding-bottom: 0;
                }
                &-navigation {
                    margin-left: -1rem;
                }
            }
        }
    }


    .account {

        .column {
            .block {
                &-addresses-list{
                    .block-content{
                        padding-top: 2rem;
                    }
                }
                &-content {
                    .box {
                        width: 49%;

                        &:nth-of-type(3n) {
                            margin-right: 2%;
                        }

                        &:nth-of-type(2n) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .cart {
        &-summary{
            #block-discount{
                margin-left: 0;
                width: 100%;
            }
        }
        &-container {
            form {
                width: 100%;
            }
        }
    }
    #checkout-shipping-method-load{
        padding: 1rem 2rem;
    }
    .opc{
        &-progress{
            &-bar{
                max-width: 100%;
            }
        }
    }
}
@media only screen and (max-width: #{$mediumMaxBreakpoint}) /* 1200px */{
    header {
        .header {
            &-main {
                &-search{
                    margin-left: 0;
                }
                &-search, &-account{
                    margin-right: 1.5rem;
                }
                &-logo {
                    .logo{
                        img{
                            display: none;
                        }

                    }
                }
                &-navigation{
                    ul{
                        li{
                            &.parent{
                                > a{
                                    padding-left: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .page{
        &-wrapper{
            .breadcrumbs{
                padding-top: 1.4rem;
                padding-bottom: 1.4rem;
            }
        }
    }
    .products {
        &.list {
            .item {
                width: 49%;
                margin-right: 2%;

                &:nth-of-type(3n){
                    margin-right: 2%;
                }
                &:nth-of-type(2n){
                    margin-right: 0;
                }
            }
        }
    }
    body {
        &:not(.catalog-product-view) {
            .webcrossing-widget-product-slider {
                .splide {
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            }
        }
    }
    .splide {
        &__arrow {
            &.splide {
                &__arrow {
                    &--prev, &--next {
                        width: .8rem;
                        height: 3.1rem;
                        &:before{
                            width: 100%;
                            height: 100%;
                            content: "";
                            background: no-repeat center center;
                            background-size: cover;
                        }
                    }
                    &--prev {
                        left: 1rem;
                        &:before{
                            background-image: url($imageDirResp + "arrow-left.png");
                        }
                    }
                    &--next {
                        right: 1rem;
                        &:before{
                            background-image: url($imageDirResp + "arrow-right.png");
                        }
                    }
                }
            }

        }
    }
    .cms-page-view, .catalog-product-view {
        .header-main {
            &-container {
                box-shadow: none;
            }
        }
    }

    .catalog-product-view{
        .product {
            &-info {
                &-media {
                    max-width: 48%;
                }
                &-main{
                    width: 48%;
                }
            }
        }
    }
    .webcrossing-widget-image-slider {
        .block-content {
            .splide {
                &__arrows {
                    display: none;
                }
            }
        }
    }
    footer{
        .footer {
            &-boxes {
                .inner{
                    &-container{
                        padding-top: 8rem;
                        padding-bottom: 8rem;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: #{$mediumBreakpoint}) /* 1024px */ {
    .mm {
        &-list {
            &view{
                .mm{
                    &-panel{
                        padding: 0;
                    }
                }
                .submenu{
                    li{
                        padding-left: 3rem;
                    }
                }
            }
            &item {
                &_opened{
                    > a.level-top{
                        color: $colorPrimary !important;
                    }
                    .mm-btn_next{
                        background: transparent !important;
                        &:after{
                            transform: rotate(45deg);
                        }
                    }
                }
                .mm-btn_next::before {
                    display: none;
                }
                &::after{
                    left: 0;
                }
            }
        }
        &-navbars{
            &_bottom{
                border:0;
                position: initial;
                top: initial;
                .mm{
                    &-navbar{
                        flex-direction: column;
                    }
                }
            }
            &_top{
                border:0;
                position: initial;
                .nav-toggle-close{
                    position: fixed;
                    left: 3rem;
                    top: 3rem;
                    z-index: 222;
                }
                .section  {
                    width: 50%;
                    li{
                        &:nth-of-type(1){
                            a{
                                &:after{
                                    content: "Mein Konto";
                                }
                            }
                        }
                    }
                    a{
                        padding: 0;
                    }
                    i{
                        font-size: 2.2rem;
                        margin-right: 1rem;
                        font-weight: 300;
                    }
                }
                .mm {
                    &-navbar {
                        height: auto;
                        @include flexWrap;
                        padding:  2rem 3.5rem !important;
                        > :not(img):not(.mm-btn){
                            flex: none;
                        }
                    }
                    &enu-top {
                        &-account{
                            a{
                                color: $colorPrimary;
                            }
                        }
                        &-cart{
                            .minicart-wrapper{
                                .counter {
                                    &.empty{
                                        .counter {
                                            &-number {
                                                display: none;
                                                &:after{
                                                    content: "0";
                                                    line-height: 2.3rem;
                                                }
                                            }
                                        }
                                    }
                                    &-number {
                                        background: $colorPrimary;
                                        display: inline-block;
                                        color: $colorWhite;
                                        width: 2.3rem;
                                        height: 2.3rem;
                                        font-size: 1.2rem;
                                        line-height: 2.3rem;
                                        text-align: center;

                                        border-radius: 50px;
                                        font-weight: 800;
                                        letter-spacing: -1px;
                                        vertical-align: top;
                                    }
                                    &-label{
                                        display: none;
                                    }
                                }
                                a{
                                    padding: 0;
                                    display: block;
                                    margin: 0;
                                    position: relative;
                                    text-align: right;
                                    &:before{
                                        content: "\f07a";
                                        @include fontIcon;
                                        color: $colorPrimary;
                                        font-size:  2.2rem;
                                        font-weight: 300;
                                        display: inline-block;
                                        margin-top: 3px;

                                    }
                                    .text{
                                        display: none;
                                    }

                                }
                            }
                        }
                        &-search {
                            width: 100%;

                            label {
                                display: none;
                            }

                            .block {
                                &-search {
                                    border: 1px solid $colorLightGrey;
                                    -webkit-border-radius: 5px;
                                    -moz-border-radius: 5px;
                                    border-radius: 5px;
                                }
                            }

                            form {
                                @include flex;
                                align-items: center;

                                .search {
                                    width: 100%;

                                    label, .nested {
                                        display: none;
                                    }
                                }
                                input {
                                    border: 0;
                                    background: transparent;

                                }
                                .actions {
                                    button {
                                        padding: .2rem 1rem;
                                        background: transparent;
                                        border: 0;

                                        span {
                                            display: none;
                                        }

                                        &:before {
                                            content: "\f002";
                                            @include fontIcon;
                                            font-size: 2rem;
                                            color: $colorPrimary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-panel{
            overflow: inherit;
            padding-right: 0;
            padding-left: 0;
            position: initial;
            &:before, &:after{
                display: none;
            }

            &s{

                > .mm-panel {

                    > .mm-navbar + .mm-listview {
                        margin: 0;
                    }
                }
            }

            ul{
                &.level0{
                    padding-bottom: 2rem;
                }
                li{
                    a{
                        padding: 2rem 3.5rem;
                        box-shadow: 0;
                        border:0;
                        &.mm-btn_next{
                            height: 6rem;

                        }
                        &.level-top{
                            @include font;
                            font-size: 1.7rem;
                            color: $colorPrimary;
                            font-weight: bold;
                        }
                        &:before{
                            display: none;
                        }
                        &:after{
                            transform: rotate(135deg);
                            transition: all ease .2s;
                        }

                    }
                }
            }
        }
        &-menu{
            background: $colorWhite;
            &_offcanvas.mm-menu_opened{
                display: block;
            }
        }
        &-wrapper_opened{
            .mm{
                &-page__blocker {
                    opacity:1;
                }
                &-menu_position-front{
                    overflow: auto;
                }
            }
        }
        &-page__blocker {
            opacity: 0;
            transition: all ease .2s;
            background: rgba($colorSecondary, .8);
        }
        &-slideout{

        }

        &enu{
            &-top{
                &-close{
                    display: none;
                }
            }
        }
    }
    .checkout-index-index{
        header{
            .header{
                &-main{
                    display: none;
                }

            }
        }
    }
    header {
        .inner{
            &-top{
                &-container{
                    width: 100%;
                    position: initial;

                }
            }
            &-main{
                &-container{
                    width: 100%;
                    flex-wrap: wrap;
                    &.left{
                        align-items: flex-start;
                        > .section{
                            @include contentInside;
                            margin: 0;
                            padding-top: 1.3rem;
                            padding-bottom: 1.3rem;
                        }
                    }
                    &.right{
                        position: absolute;
                        justify-content: space-between;
                        padding-top: 1.7rem;
                        padding-right: 2rem;
                        max-width: 17rem;
                    }
                }
            }
        }
        .header {
            &-top{
                &-container{
                    padding: 0;
                    @include flexWrap;
                }
                &-logo{
                    padding-left: 2rem;
                }
                &-info{
                    width: 100%;
                    max-width: 100%;
                    justify-content: center;
                    padding-top: 1rem;
                    ul{
                        justify-content: center;
                    }
                }
                &-search{
                    .active{
                        .block-content{
                            width: 30rem;
                            left: initial;
                            right: 0;
                            border-top: 0;
                            margin-top: 2rem;
                            margin-right: 2rem;
                        }
                    }
                }
            }
            &-main {
                padding-bottom: 1rem;
                display: none;
                &.sticky{
                    top: 0;
                    background: $colorWhite;
                    padding-bottom: 0;
                    .inner{
                        &-main {
                            &-container {
                                &.right{
                                    width: 40%;
                                }
                            }
                        }
                    }
                    .header{
                        &-main{
                            &-logo{
                                display: none;
                            }
                            &-navigation{
                                .nav-toggle.action{
                                    margin-top: 0;
                                }
                                &:after{
                                    display: none;
                                    margin-top: 0;
                                }
                            }
                            &-container{
                                padding-top: 1rem;
                                padding-bottom: 1rem;
                                flex-direction: row-reverse;
                                align-items: center;
                            }
                        }
                    }
                }
                &-logo{
                    .logo{
                        width: auto;
                        padding: 1rem 0 0;
                        display: inline-block;
                        img{
                            display: block;
                            max-height: 6.5rem;
                        }
                        &:after{
                            display: none;
                        }
                    }
                }
                &-container{
                    width: 100%;
                    @include flex;
                    flex-direction: column-reverse;

                }
                &-title{
                    display: none;
                }
                &-account{
                    a{
                        display: inline-block;
                        line-height: 0;
                        margin-top: .2rem;
                        i{
                            font-weight: 300;
                            font-size: 2.5rem;
                        }
                    }

                }
                &-account, &-search{
                    margin-right: 2.5rem;
                }
                &-cart{
                    .minicart-wrapper {
                        a {
                            @include flex;
                            align-items: center;
                            &:before{
                                font-size: 2.5rem;
                                font-weight: 300;
                            }
                            .counter {
                                height: auto;
                                vertical-align: center;
                                @include flex;
                                align-items: center;
                                padding-left: .5rem;
                                &-number {
                                    position: relative;
                                    right: inherit;
                                    top: inherit;
                                    width: 2.5rem;
                                    height: 2.5rem;
                                    font-size: 1.7rem;
                                    line-height: 2.5rem;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
                &-navigation{
                    margin-left: 0;
                    @include flex;
                    .nav-toggle.action{
                        padding: 0;
                        width: 30px;
                        border: 0;
                        background: transparent;
                        @include flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        &:before, &:after, span{
                            content: "";
                            width: 100%;
                            height: 3px;
                            background: $colorSecondary;
                            margin-top: .8rem;
                            display: inline-block;
                        }
                        &:before{
                            margin-top: 0;
                        }
                    }
                    &.mobile{
                        @include flex;
                        align-items: center;
                        order: 2;
                        width: 100%;
                        background: $colorDarkBrown;
                    }
                    &.desktop{
                        display: none;
                    }
                }
                &-search{
                    width: 100%;
                    .block {
                        &-title {
                            display: none;
                        }
                        &-content{
                            display: block;

                        }
                    }
                    form{
                        @include flex;
                        align-items: center;
                        border: 1px solid $colorLightGrey;
                        border-radius: 5px;
                        .search{
                            width: 100%;
                            label, .nested{
                                display: none;
                            }
                        }
                        input{
                            border: 0;
                            background: transparent;

                        }
                        .actions{
                            button{
                                padding: .2rem 1rem;
                                background: transparent;
                                span{
                                    display: none;
                                }
                                &:before{
                                    content: "\f002";
                                    @include fontIcon;
                                    font-size: 2rem;
                                    color: $colorPrimary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .webcrossing-widget {
        &-image {
            &-box{
                .box{
                    padding-top: calc(260.44 / 700 * 100%);
                    .image{
                        img{
                            min-height: 100%;

                            max-width: 2000%;
                            width: auto;
                        }
                    }
                    .info{
                        h2, span{
                            font-size: 2.9rem;
                            line-height: 2.5rem;
                        }
                    }
                }
            }
            &-slider {
                .block {
                    &-content {
                        .splide {
                            &__arrows {
                                display: none;
                            }

                            &__pagination {
                                @include contentMedium;

                                z-index: 5;
                                display: inline-block;
                                @include flex;
                                align-items: center;
                                justify-content: center;
                                bottom: 1rem;
                            }

                            &__track {
                                > ul {
                                    > li {

                                        &:before {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            width: 100%;
                                            bottom: 0;
                                            height: 60%;
                                            z-index: 1;
                                            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#484c52+1,484c52+100&0+0,0.75+100 */
                                            background: -moz-linear-gradient(top, rgba(72, 76, 82, 0) 0%, rgba(72, 76, 82, 0.01) 1%, rgba(72, 76, 82, 0.8) 100%); /* FF3.6-15 */
                                            background: -webkit-linear-gradient(top, rgba(72, 76, 82, 0) 0%, rgba(72, 76, 82, 0.01) 1%, rgba(72, 76, 82, 0.8) 100%); /* Chrome10-25,Safari5.1-6 */
                                            background: linear-gradient(to bottom, rgba(72, 76, 82, 0) 0%, rgba(72, 76, 82, 0.01) 1%, rgba(72, 76, 82, 0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00484c52', endColorstr='#bf484c52', GradientType=0); /* IE6-9 */

                                        }
                                    }
                                }
                            }
                        }
                        ul {
                            li {
                                button {
                                    cursor: pointer;

                                    &.is-active {
                                        background: $colorWhite;
                                        opacity: 0.8;
                                    }
                                }

                                .image {
                                    z-index: 0;
                                }

                                .info {
                                    width: 100%;
                                    @include contentMedium;
                                    z-index: 2;
                                    bottom: 4rem;
                                    top: inherit;
                                    .actions {
                                        a {
                                            margin-right: 1rem;
                                        }
                                    }
                                    &.right, &.left {
                                        right: inherit;
                                        left: 50%;


                                        transform: translate(-50%, 0);
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
        &-category{
            &-boxlist{
                max-width: 100%;
                padding: 0;
                padding-top: 4rem;
                padding-bottom: 4rem;
                .block{
                    &-title{
                        @include contentMedium;
                    }
                    &-content{
                        max-width: 100%;
                        padding: 0;
                    }
                }
                ul{
                    li{
                        width: 49%;
                        padding-top: calc(168.44 / 390.34 * 100%);
                        margin-right: 2%;
                        .image{
                            img{
                                min-height: 100%;
                                max-width: 100%;
                                min-width: 150%;
                            }
                        }
                        &:nth-of-type(3n){
                            margin-right: 2%;
                        }
                        &:nth-of-type(2n){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &-teaser {
            &-box {

                .block{
                    &-content{
                        @include flexWrap;

                    }
                }
                .box{
                    width: 100%;
                    padding: 10rem 0;

                    &.right{
                        background: $colorDarkGreen;
                        .info{
                            .actions{
                                padding-top: 3rem;
                            }
                        }
                    }
                }
                .info {
                    @include contentMedium;
                    .description {
                        padding-bottom: 0;
                        font-size: 1.5rem;
                        font-weight: 300;
                    }

                }
            }
        }
    }
    .shipping{
        &-information{
            margin-top: 0;
            .ship{
                &-to{
                    margin-bottom: 2rem;
                }
            }
            &-title{
                @include flex;
                justify-content: space-between;
                .action{
                    &-edit{
                        background: transparent;
                        padding: 0;
                        span{
                            font-size: 0;
                            &:before{
                                content: "\f044";
                                @include fontIcon;
                                font-size: 1.6rem;
                                color: $colorPrimary;
                            }
                        }
                    }
                }
            }
        }
    }
    .opc{
        &-progress-bar, &-wrapper, &-sidebar{
            width: 100%;
            max-width: 100%;
        }
        &-sidebar{
            margin-bottom: 6rem;
        }
        &-block{
            &-summary, &-shipping-information{
                max-width: 49%;
                width: 100%;
                display: inline-block;
                vertical-align: top;

            }
            &-summary{
                margin-right: 1%;

            }
        }
    }
    .catalog-product-view{
        .product {
            &-info {
                &-media {

                }
                &-main{

                    width: 48%;
                }
                &-description{
                    padding: 0 0 5rem;

                }
            }
            &-add{
                &-form{
                    padding-right: 0;
                }
            }
        }
    }
    .login{
        &-container{
            @include flexWrap;
            padding-bottom: 5rem;
            .block{
                width: 100%;
            }
        }
    }
    .cart {
        &-sidebar{
            width: 100%;
            @include flex;
            margin-top: 7rem;
        }
        &-container {
            form.form-cart{
                width: 100%;
                order: 0;
            }
        }
        &-summary, &-membership{
            width: 100%;
            margin-top: 0;
        }
        &-membership{

            margin-left: 2%;
        }
        &-summary{
            order: 0;

            .summary{
                &.title{
                    padding-bottom: 3rem;
                }
            }
        }
    }
    footer{
        .inner{
            &-container{
                @include flexWrap;
            }
        }
        .footer {
            &-boxes{
                &-after{
                    &:after{
                        height: 95%;
                    }
                    .inner{
                        &-container{
                            max-width: 100%;
                            padding: 0;
                        }
                    }
                    &:before{
                        display: none;
                    }
                }
            }
            &-bottom{
                width: 100%;
                .section{
                    ul{
                        li{
                            font-weight: 300;
                        }
                    }
                }
                &-links, &-member{
                }
                &-contact{
                    width: 100%;
                    order: 3;
                    padding-top: 5rem;
                    padding-right: 0;
                }

                &-left{
                    @include flexWrap;
                    padding-bottom: 2rem;
                    .section{

                    }
                }
                &-right{
                    justify-content: center;
                }
            }
            &-main{
                nav{
                    > ul{
                        justify-content: center;
                        > li{
                            width: 50%;
                            ul{
                                padding-left: 5rem;
                                li{
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }
            &-top{
                ul{
                    justify-content: space-between;
                    li{
                        a{

                        }
                    }
                }
                &-left, &-right{
                    width: 100%;
                }
                &-logos{
                    padding-top: 2rem;
                    text-align: center;
                }
            }
            &-boxes {
                .inner{
                    &-container{
                        padding-top: 6rem;
                        padding-bottom: 6rem;
                        @include flexWrap;
                        justify-content: center;
                    }
                }
                &-block {
                    width: 70%;
                    margin-bottom: 6rem;
                    h2{
                        padding-bottom: 1.5rem;
                        line-height: normal;
                    }
                    a{
                        display: none;
                        width: auto;
                        &.mobile{
                            display: inline-block;
                        }
                    }
                    i{
                        margin-bottom: .5rem;
                    }
                    &.thirds{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .page {
        &-products{
            .page-title-wrapper{

            }
        }
        &-layout {
            &-storytelling-layout {
                .category-page-header-text::after {
                    display: none;
                }

                .webcrossing-widget-category-checklist {
                    .description {
                        column-count: 2;
                    }
                }

                .products {
                    &.list {
                        .product {
                            &-item {
                                width: 32%;

                                &:nth-of-type(4n) {
                                    margin-right: 1%;
                                }

                                &:nth-of-type(5n) {
                                    margin-right: 1%;
                                }

                                &:nth-of-type(3n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &-2columns-left {
                .columns {
                    @include flexWrap;

                    .column {
                        width: 100%;
                    }
                }
            }
        }
    }
    .account, .page-products {
        .sidebar-main {
            width: 100%;
        }
    }
    .account{

        .choice{
            align-items: flex-start;
            input{
                margin-top: .7rem;
            }
        }
        .actions-toolbar{
            .primary{
                margin-right: 1rem;
            }
        }
    }
}
@media only screen and (max-width: #{$mediumSmallBreakpoint}) /* 768px */ {
    header {
        .header {
            &-main {
                &-navigation{
                    .nav-toggle{
                        &.action{
                            margin-top: .2rem;
                        }
                    }
                }
            }
            &-top{
                &-logo{
                    .logo{
                        max-width: 12rem;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .block-title {
        h2 {
            font-size: 2.8rem;
            span{
                width: auto;
                display: inline-block;

            }
        }
    }
    .toolbar {
        .limiter, .sorter{
            label{
                width: 100%;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: left;
            }
            .control{
                width: 100%;
            }
            select{
                width: 100%;
                height: 4.3rem;
                font-size: 1.5rem;
            }
        }
        .limiter{
            order: 1;
        }
    }
    .webcrossing-widget {
        &-image {
            &-box{
                .block{
                    &-content{
                        @include flexWrap;
                    }
                }
                .box{
                    width: 100%;
                    padding-top: calc(260.44 / 400 * 100%);

                    .info{
                        h2, span{
                            font-size: 2.9rem;
                            line-height: 2.5rem;
                        }
                    }
                }
            }
            &-slider {
                .block-content {
                    .splide{
                        &__track{
                            > ul{
                                > li{
                                    &:before{
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        width: 100%;
                                        bottom: 0;
                                        height: 60%;
                                        z-index: 1;
                                        background: -moz-linear-gradient(top,  rgba(72,76,82,0) 0%, rgba(72,76,82,0.01) 1%, rgba(72,76,82,0.8) 100%); /* FF3.6-15 */
                                        background: -webkit-linear-gradient(top,  rgba(72,76,82,0) 0%,rgba(72,76,82,0.01) 1%,rgba(72,76,82,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
                                        background: linear-gradient(to bottom,  rgba(72,76,82,0) 0%,rgba(72,76,82,0.01) 1%,rgba(72,76,82,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00484c52', endColorstr='#bf484c52',GradientType=0 ); /* IE6-9 */
                                    }
                                }
                            }
                        }
                    }
                    ul {
                        li {
                            .image{
                                img{
                                    max-height: 100%;
                                }
                            }
                            .info {
                                h2{
                                    font-weight: 300;
                                }
                                .actions{
                                    a{
                                        padding-right: 1.5rem;
                                        padding-left: 1.5rem;
                                        text-align: center;
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                    }
                                }
                                h2, h3 {
                                    font-size: 4.5rem;
                                    margin-top: 0;
                                    line-height: 4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-product{
            &-sliderwithbanner{
                .block-title {
                    h2{
                        span{
                            width: 100%;
                        }
                    }
                    .image {

                        img{
                            max-width: 100%;
                        }

                    }
                }
            }
        }
        &-category {
            &-boxlist {
                .overlay {
                    h2{
                        padding: 0;
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
    .banner-headline-wrapper {
        &:before {
            align-items: normal;
        }
    }
    .catalog-product-view {
        .product {
            &.detailed{
                .items{
                    .item{
                        &.title{
                            &:last-child{
                                padding-right: 0;
                            }
                            a{
                                min-width: 100%;
                                max-width: 32%;
                            }
                        }
                    }
                }
            }
            &-info {
                &-media, &-main {
                    max-width: 100%;
                    width: 100%;
                    margin-right: 0;
                }

                &-detail {
                    .details {
                        ul {
                            @include flexWrap;
                            li {
                                width: 100%;
                                margin-bottom: 3rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .page-layout-2columns-left {
        .page-wrapper {
            .columns {
                width: 100%;
            }
        }
    }
    .opc{
        &-block{
            &-summary, &-shipping-information{
                max-width: 100%;
            }
            &-summary{
                margin-right: 0;
            }
        }
    }
    .shipping{
        &-address-item{
            width: 49%;

            &:nth-of-type(3n){
                margin-right: 2%;
            }
            &:nth-of-type(2n){
                margin-right: 0;
            }
        }
        &-information{
            margin-top: 4rem;
        }
    }

    .cart{
        &-sidebar{
            @include flexWrap;
        }
        &-membership, &-summary{
            width: 100%;
            margin-left: 0;
        }
        &-membership{
            margin-top: 5rem;
        }
        &-container{
            form {
                .actions {
                    flex-wrap: wrap;
                    > .action{
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 1rem;
                        line-height: normal;
                    }
                }
                &.form-cart{

                }
            }
        }
    }
    .login-container .block{
        &-customer-login{
            padding-bottom: 3rem;
        }
    }
    .account{
        .block-content {
            .box {
                width: 49%;
                &:nth-of-type(2n){
                    margin-right: 0;
                }
            }

        }
    }
    footer{
        .footer{
            &-main{
                .section{
                    width: 48%;
                }
                nav{
                    > ul{
                        >li{
                            width: 100%;
                        }
                    }
                }
                &-newsletter{
                    padding-top: 2rem;
                }
            }
            &-left{
                width: 100%;
                @include flexWrap;
            }
            &-top{
                display: none;
                &-left{
                    flex-wrap: wrap;
                }
                .inner-container{
                    padding: 0 0;
                }
                ul{
                    @include flexWrap;
                    li{
                        width: 100%;
                        text-align: center;
                        a{

                        }
                    }
                }
                &-info{
                    width: 100%;
                    max-width: 100%;
                    padding-bottom: 3.5rem;
                }
                &-logos{
                    padding-top: 6rem;
                    padding-bottom: 2.5rem;
                }
            }
            &-bottom{
                .section{
                    width: 100%;
                    text-align: center;
                    a{
                        margin: 0 auto;
                    }
                }
                &-member{
                    padding-left: 0;
                    padding-bottom: 4rem;
                    span{
                        justify-content: center;
                        width: 100%;
                    }
                }
                &-contact{
                    @include flexWrap;
                    ul{
                        width: 100%;
                        padding-bottom: 3rem;
                    }
                }
                &-links{
                    padding-right: 0;
                    order: 2;
                }
                &-left{
                    padding-bottom: 2rem;
                }
            }
        }
    }
}
@media only screen and (max-width: #{$mobileBreakpoint}) /* 480px */ {
    header{
        .header{
            &-main{
                &.sticky {
                    .inner {
                        &-main {
                            &-container {
                                &.right {
                                    padding-right: 3rem;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .webcrossing-widget {
        &-product{
            &-sliderwithbanner{
                .block-content .actions a{
                    width: auto;
                }
            }
        }
    }
    .account {
        .actions{
            .action{
                width: auto;
                display: inline-block;
            }
            &-toolbar{
                width: 100%;
                @include flexWrap;
                .primary{
                    margin-bottom: 1rem;
                    margin-right: 0;
                }
                .primary, .secondary{
                    width: 100%;
                    a{
                        width: 100%;
                    }
                }
            }
        }
        .column .block-content .box {
            width: 100%;
            margin-right: 0;
            &:nth-of-type(2n) {
                margin-right: 0;
            }
            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
        .block-dashboard-orders .block-title.order a.action.view{
            position: relative;
            width: 100%;
            height: auto;
            margin-top: 2rem;
            padding: 1rem 2rem;
            right: inherit;
            bottom: inherit;
        }
    }
    .catalog-product-view {
        .product {
            &-add-form {
                .box-tocart {
                    .fieldset {
                        @include flexWrap;

                        .actions {
                            width: 100%;
                            margin-left: 0;
                            margin-top: 1.5rem;

                            button {
                                width: 100%;
                            }
                        }
                    }

                }
            }
            &-info{
                &-main{
                    .page-title-wrapper{
                        h1{
                            font-size: 2.6rem;
                        }
                    }
                }
            }
            &.detailed{
                .items{
                    .item.content{
                        .inner-content{
                            &.facts, &.awards{
                                ul{
                                    li{
                                        @include flexWrap;
                                        margin-bottom: 1.5rem;
                                        span{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-tab-expertise{
                @include flexWrap;
            }
        }
    }
    .products{
        &.list{
            .product{
                &-item-name{
                    display: block;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    min-height: 7rem;
                }
            }
        }
    }

    .shipping{
        &-address-item{
            width: 100%;
            margin-right: 0;
            &:nth-of-type(3n){
                margin-right: 0;
            }
            &:nth-of-type(2n){
                margin-right: 0;
            }
        }
    }
    .checkout-container{
        padding-right: 0;
        padding-left: 0;
    }
    .opc {
        &-block {
            &-summary{
                padding: 2rem;
            }
            &-shipping {
                &-information {
                    .action {
                        width: auto;
                    }
                }
            }
        }
    }
    .category-page-header{
        .category{
            &-description{
                max-width: 100%;
            }
        }
        &-text{
            padding: 3rem 3rem 1rem;
        }
    }
    .page {
        &-layout {
            &-storytelling-layout {
                .webcrossing-widget-category-checklist {
                    .description {
                        column-count: 1;
                    }
                }

                .products {
                    &.list {
                        .product {
                            &-item {
                                width: 49%;
                                margin-right: 2%;

                                &:nth-of-type(3n) {
                                    margin-right: 2%;
                                }
                                &:nth-of-type(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    footer{
        .footer-boxes-block{
            width: 100%;
        }
    }
    .toolbar {
        &-amount{
            width: 100%;
        }
        .sorter {
            margin-left: 0;
            width: 100%;
        }
    }
    #checkout-shipping-method-load{
        padding: 1rem ;
    }

}
@media only screen and (max-width: #{$smallmobileBreakpoint}) /* 360px */ {
    .category-page-header {
        &-text {
            padding:  2rem 1rem 1rem;
            &:after{

                height: 100%;
            }
        }
    }
}