.title {
  font-weight: bold;
  line-height: 1;
  font-size: 2.4rem;
  padding-bottom: 1.6rem;
  display: block;
  border-bottom: 1px solid $colorBorder;
  word-break: break-word;
  hyphens: auto;
}
.totals {
  .title {
    font-size: 1.6rem;
    border-bottom:0;
    font-weight:400;
    padding-bottom:0;
  }
}
.cart {
  &-price {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0;
  }
  &-sidebar{
    width: 36rem;
    margin-top: 2.8rem;
  }
  &-membership{
    padding: 2.1rem 3rem;
    background: $colorSecondary;
    position: relative;
    margin-top: 5rem;
    font-size: 1.6rem;
    h3{
      text-align: left;
      font-size: 2rem;
    }

    &:after{
      position: absolute;
      width: 8rem;
      height: 9rem;

      bottom: 0;
      right: 0;
      content: "";
      background-size: 12rem 12rem;
    }
  }
  &-container {
    padding-bottom: 28rem;
    @include flexStart;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    #gift-options-cart {
      display: none;
    }

    form {
      &.form-cart{
        width: calc(100% - 39rem);
      }
      .actions {
        padding-top: 2rem;
        @include flexEnd;
        .action {
          margin-left: 2rem;
          border: 1px solid $colorSecondary;
          margin-right: 0;
          width: 33%;
          text-align: center;
          color: #4B5458;
          font-size: 1.4rem;
          &.continue {
            margin-left: 0;
          }
        }
        &-toolbar {
          align-items: center;
          .towishlist span {
            display: none;
          }
        }

        &-edit {
          display: none;
        }

      }
    }



    caption.table-caption {
      display: none;
    }
  }

  &-summary {
    border: 1px solid $colorLighterGrey;
    padding: 2.1rem 3rem;

    .block.shipping {
      display: none;
    }
    .checkout{
      margin-top: 1.6rem;
      &.methods{
        li{
          width: 100%;
          margin-right: 0;
        }
      }
      .action{
        margin-top: 0;
        margin-right: 0;
      }
    }
    button, .primary {
      width: 100%;
    }

    .discount{
      cursor: pointer;
      margin-top: 1.6rem;
      color: $colorPrimary;
      font-size: 1.6rem;
      padding-bottom: 2rem;
      .title{
        border-bottom: 0;
      }

      .field {
        margin-bottom: 1.5rem;
      }

      .fieldset.coupon {
        label {
          display: none;
        }
      }

      .actions-toolbar {
        width: 100%;
        position: relative;
        left: inherit;
        top: inherit;
        .primary{
        }
      }
    }
  }

  &-totals {
    padding: 1.6rem 0 0;
    border-bottom: 1px solid $colorSecondary;
    .table {
      &-caption {
        text-align: right;
      }
      &.totals {
        width: 100%;

        .totals-tax {
          padding-top: 0;
          color: rgba(0, 0, 0, 0.5);
        }
        th, td {
          line-height: 1;
          padding: 0;
          font-size: 1.6rem;
          padding-bottom: 1.6rem;
          font-weight: normal;
        }
        .sub {

        }
        th.mark {
          text-align: left;
        }
        td.amount {
          text-align: right;
        }
      }
      &-wrapper {
        tr.grand.totals {
          color: black;
        }
        .load.indicator {
          background-color: rgba($colorLighterGrey, 0.5);
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }
          > span {
            display: none;
          }
        }

        .loading-mask {
          bottom: 0 !important;
          left: 0 !important;
          margin: auto;
          position: fixed !important;
          right: 0 !important;
          top: 0 !important;
          z-index: 100;
          background: rgba($colorLighterGrey, 0.5);
          .loader > {
            img {
              bottom: 0;
              left: 0;
              margin: auto;
              position: fixed;
              right: 0;
              top: 0;
              z-index: 100;
            }
            p {
              display: none;
            }
          }
        }
        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }

        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
}
.form-discount {
  cursor: pointer;
  margin-top: 1.6rem;
  color: $colorPrimary;
  font-size: 1.6rem;
  .label{
    @extend .title
  }
  .title {
    border-bottom: 0;
  }

  .field {
    margin-bottom: 1.5rem;
  }

  .fieldset.coupon {
    label {
      display: none;
    }
  }

  .actions-toolbar {
    width: 100%;
    position: relative;
    left: inherit;
    top: inherit;
    .action{
      &.action-apply{
        margin-top: 0;
        @extend .action, .primary;
      }
    }
  }
}