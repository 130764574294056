// width
$maxWidth: 192rem;
$contentWidth: 144rem;
$mediumContent: 105rem;
$smallContent: 65rem;

// breakpoints
$maxBreakpoint: 1440px;
$mediumMaxBreakpoint: 1200px;
$mediumBreakpoint: 1024px;
$mediumSmallBreakpoint: 768px;
$smallBreakpoint: 650px;
$mobileBreakpoint: 480px;
$smallmobileBreakpoint: 360px;

// images
$imageDir: "../../images/";
// images
$imageDirResp: "../images/";
// icons
$iconDir: "../../images/icons/";

// colors + shades
$colorPrimary: #494039;
$colorSecondary: #f3eee8;
$colorGrey: #776860;
$colorDarkGrey: #484C52;
$colorLightGrey: #e3dcd6;
$colorBeige: #E4DDD6;
$colorBorder: #d1d1d1;
$colorError: #c1121c;
$colorSuccess: #B0BAA5;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorBusiness: #2b292c;
$colorButton: #7f834c;
$colorLighterGrey: #D5D5D5;
$colorWhite: #fff;
$colorBlack: #000;
$colorRed: #EE2D2D;
$colorGreen: #7f834c;
$colorDarkGreen: #54A42F;
$colorText: #503825;
$colorLighterBrown: #c3b7a9;
$colorDarkBrown: #9d9287;

// icons: use fontawesome pro unicode - leave empty for no icon
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f2ed";
$iconEdit: "\f040";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
$iconSearch: "\f002";
$iconCart: "\f07a";


